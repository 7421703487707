@import 'src/styles/variables';

.chat-item {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 16px;

  &.my-message {
    flex-direction: row-reverse;

    .chat-item-info {
      &--header {
        justify-content: flex-end;
      }

      &--body {
        border-radius: 16px 1px 16px 16px;
        background-color: $primary-10;
        align-self: flex-end;
      }
    }
  }

  &:last-child {
    margin-top: 0;
  }

  .chat-item-info {
    display: flex;
    flex-direction: column;

    &--header {
      display: inline-flex;
      align-items: center;
      margin-bottom: 8px;

      .header-item--name {
        @include font_P3_reg($primary-60);
        display: inline-flex;
        align-items: center;

        .ant-tooltip--wrapper {
          @include lineClamp(1);
          max-width: 300px;
          white-space: break-spaces;
          word-break: break-all;

          @media (max-width: $mediaWidthMD) {
            max-width: 80%;
          }
        }
      }
    }


    &--body {
      padding: 12px 16px;
      border: 1px solid $primary-10;
      border-radius: 1px 16px 16px 16px;
      display: inline-flex;
      width: auto;
      align-self: flex-start;

      .body-message {
        @include font_P1_reg;
        white-space: pre-line;
        word-break: break-word;

        a {
          @include font_P1_reg_und;
        }
      }

      .body-files {
        margin-top: 8px;
      }
    }
  }
}
