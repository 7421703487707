.feedback-modal {
  &--body {
    display: flex;
    flex-direction: column;

    .body-item {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      .ant-form-item {
        width: 100%;
        margin-bottom: 0 !important;

        .rating-element {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .ant-modal-footer {
    margin-top: 48px;
    justify-content: center;
  }
}
