@import "src/styles/variables";

.menu-list-item {
  @include font_P1_reg;
  display: flex;
  align-items: center;

  .icon-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border: 1px solid transparent;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.3s ease;

    &.logout-action {
      margin-top: 90px;
    }

    .react-custom-icon {
      color: $primary-100 !important;
    }
  }

  &:not(.mobile-item).active-item {
    border-radius: 50%;
    border: 1px solid $primary-100;
  }

  &:not(.active-item):hover {
    .icon-item {
      border: 1px solid $primary-100;
    }
  }

  &.mobile-item {
    cursor: pointer;

    &:hover {
      .icon-item {
        border: 1px solid transparent;
      }
    }
  }

  &:not(.mobile-item).active-item {
    border-radius: 50%;
    border: 1px solid $primary-100;
  }

  &:not(.active-item):hover {
    .icon-item {
      border: 1px solid $primary-100;
    }
  }

  &.mobile-item {
    cursor: pointer;

    &:hover {
      .icon-item {
        border: 1px solid transparent;
      }
    }
  }
}
