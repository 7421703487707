@import '../../styles/variables';

.profile-settings {
  height: 100%;
  padding: 16px 24px 0;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;

  &-title {
    display: flex;
    align-items: center;

    .title-text {
      @include font_H1;
    }

    .expert-auth-widget-navigation {
      margin-left: auto;
    }
  }

  &-content {
    padding: 24px 0;
    display: flex;
    column-gap: 24px;

    @media screen and (max-width: $mediaWidthLG) {
      flex-direction: column;
    }
  }

  &-inside {
    width: calc(100% - 209px);

    @media screen and (max-width: $mediaWidthLG) {
      width: 100%;
    }

    .content-title {
      @include font_T3_bold($blue-100);
      line-height: 31px;
      margin-bottom: 8px;
    }

    .content-title-line {
      width: 100%;
      height: 1px;
      background-color: $primary-10;
      margin-bottom: 32px;
    }

    .content-inside {
      max-width: 394px;

      @media screen and (max-width: $mediaWidthSM) {
        max-width: 100%;
      }
    }
  }
}
