@import "src/styles/variables";

.transactions-list {
  height: 100%;
  display: flex;
  flex-direction: column;

  &.transactions-list-expert {
    overflow: hidden;

    .transactions-list--header, .transactions-table--header {
      padding: 0 24px 16px 24px;
    }

    &--pagination {
      & + div {
        height: calc(var(--app-height) - 76px - 64px - 72px - 74px - 68px);
      }
    }

    &--body {
      height: calc(var(--app-height) - 76px - 64px - 72px - 68px);
    }
  }

  &--header {
    display: flex;
    align-items: center;
    padding: 24px;

    .header-title {
      @include font_H1;
      flex: 1 0 auto;
    }

    .header-filters {
      position: relative;
      display: inline-flex;
      cursor: pointer;

      &.has-filters::after {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: $orange-100;
      }

      .react-custom-icon {
        color: $primary-100 !important;
      }
    }
  }

  &--subtitle {
    margin-top: -15px;
    padding: 0 24px 8px 24px;
  }

  &--img {
    width: 100%;
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: inline-flex;
      width: 100%;
      max-width: 560px;
    }
  }

  &--empty {
    @include font_P1_med;
    text-align: center;
  }

  &--pagination {
    display: flex;
    justify-content: flex-end;
    padding: 8px 24px 24px 24px;
    order: 2;

    & + div {
      height: calc(100% - 76px - 64px - 72px - 74px - 68px);
    }

    .ant-pagination.paginator-element {
      margin: 16px 0 8px 0;

      .ant-pagination-prev, .ant-pagination-next {
        .ant-pagination-item-link {
          color: $orange-100 !important;
        }

        &.ant-pagination-disabled {
          .ant-pagination-item-link {
            color: $primary-15 !important;
          }
        }
      }

      .ant-pagination-item-active {
        background-color: $primary-10;
        border-color: $primary-10;
      }
    }
  }

  &--body {
    position: relative;
    flex: 1 0 auto;
    height: calc(var(--app-height) - 76px - 64px - 72px - 68px);
    box-sizing: border-box;
    overflow-y: auto;
    padding: 0 24px 16px 24px;
    overflow-x: hidden;

    .spin-element {
      position: fixed;
      top: 0;
      left: 0;
    }
  }

  @media (max-width: $mediaWidthMD) {

    &--header {
      padding: 24px 16px;
    }

    &--subtitle {
      padding: 0 16px 8px 16px;
    }

    &--pagination {
      padding: 8px 16px 24px 16px;
    }

    &--body {
      padding: 0 16px 16px 16px;
    }
  }
}
