@import '../../../styles/variables';

.rejoin-call {
  position: fixed;
  right: 24px;
  top: 16px;
  width: 394px;
  background-color: $call-bg;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;

  @media screen and (max-width: $mediaWidthXS) {
    width: calc(100% - 48px);
    margin: 0 24px;
    right: 0;
  }

  .incoming-call-inside {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    width: 394px;

    .photo {
      width: 80px;
      height: 80px;
      margin-bottom: 24px;

      @media screen and (min-width: $mediaWidthXLL) {
        width: 90px;
        height: 90px;
      }
      @media screen and (min-width: $mediaWidthMax) {
        width: 100px;
        height: 100px;
      }

      img,
      .default-image .react-custom-icon-big div,
      .default-image svg {
        width: 80px;
        height: 80px;
        border-radius: 50%;

        @media screen and (min-width: $mediaWidthXLL) {
          width: 90px;
          height: 90px;
        }
        @media screen and (min-width: $mediaWidthMax) {
          width: 100px;
          height: 100px;
        }
      }
    }

    .fio {
      margin-bottom: 8px;
      text-align: center;
      @include font_P1_med($white);
    }

    .call-type {
      height: 24px;
    }

    .actions {
      margin-top: 24px;
    }
  }

  .close-rejoin-call {
    position: absolute;
    right: 20px;
    top: 16px;

    svg {
      color: $white;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.75;
    }
  }
}
