@import "src/styles/variables";

.history-base {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  &--header {
    .header-title {
      @include font_H1;
      margin-bottom: 8px;
    }

    .header-subtitle {
      @include font_P1_med($primary-80);
    }
  }

  &--img {
    flex: 1 0 auto;
    margin: 16px 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    img {
      display: inline-flex;
      width: 100%;
      max-width: 620px;
      min-width: 250px;
    }
  }
}
