@import "src/styles/variables";

.appointments-card {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid $primary-05;

  &.awaiting {
    background-color: $blue-05 !important;
  }

  &--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    .header-status {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 9px;

      &--point {
        display: inline-flex;
        min-width: 6px;
        width: 6px;
        min-height: 6px;
        height: 6px;
        background-color: $orange-100;
        border-radius: 50%;

        &.approved {
          background-color: $blue-100;
        }

        &.awaiting {
          background-color: $orange-100;
        }

        &.canceled {
          background-color: $red-100;
        }

        &.past {
          background-color: $primary-60;
        }
      }

      &--name {
        @include font_P3_reg($primary-80);
      }
    }

    .header-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;

      &--item {
        display: inline-flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;

        &.cancel-type {
          .react-custom-icon {
            color: $red-100 !important;
          }
        }

        &.approve-type {
          .react-custom-icon {
            color: $green-100 !important;

            path {
              fill: $green-100 !important;
            }
          }
        }
      }
    }
  }

  &--body {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .body-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      &:last-child {
        justify-content: flex-start;
      }

      &--item {

        &.item-avatar {
          display: inline-flex;
        }

        &.item-name {
          @include font_P1_reg($blue-100);
          width: 100%;
          cursor: help;
        }

        &.item-note {
          margin-left: auto;
        }

        &.item-type {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          gap: 4px;

          .type-wrapper--icon {
            display: inline-flex;

            .react-custom-icon {
              color: $orange-100 !important;
            }
          }

          .type-wrapper--name {
            @include font_P3_semibold($primary-80);
          }
        }

        &.item-today {
          @include font_P3_semibold($primary-80);
        }

        &.item-price {
          @include font_T4_sb($primary-80);
          margin-left: auto;
        }

        &.item-time {
          @include font_P3_reg($primary-80);
        }
      }
    }
  }
}
