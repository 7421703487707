@import '../../styles/variables';

.static-page {
  &.dialog-static-text {
    .spinner-section {
      height: 360px !important;
    }
  }

  .static-page-title {
    text-align: center;
    @include font_T1;
  }

  .static-page-text {
    @include font_P1_reg;
  }
}
