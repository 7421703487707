@import 'src/styles/variables';

.expert-list {
  transition: all 0.3s ease;

  &--header {
    padding: 0 24px 24px 24px;
  }

  &--body {
    .body-item {
      cursor: pointer;
      border-bottom: 1px solid $primary-10;

      &.active-expert {
        background-color: $primary-10;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &--empty {
    @include font_P2_reg($primary-80);
    text-align: center;
  }
}
