@import "src/styles/variables";

.transactions-empty--inside {
  height: 100%;
  padding: 24px 16px ;
  background-color: $white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .inside--title {
    @include font_H1;
    margin-bottom: 8px;

    &.small-title {
      @include font_T3_bold;
    }
  }

  .inside--subtitle {
    @include font_P1_med($primary-80);
    margin-bottom: 16px;
  }

  .inside--img {
    padding: 0 24px 24px 16px;
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    img {
      max-width: 560px;
      min-width: 280px;
      width: 100%;
    }
  }

  @media (max-width: $mediaWidthMD) {

    .inside--subtitle {
      margin-bottom: 0;
    }

    .inside--img {
      margin-top: 24px;
      padding: 0;
      flex: 0 0 auto;
    }
  }
}
