@import '../../../../styles/variables';

.profile-left-menu {
  display: flex;
  flex-direction: column;
  width: 300px;

  @media screen and (max-width: $mediaWidthMax) {
    width: 240px;
  }

  @media screen and (max-width: $mediaWidthXLL) {
    width: 185px;
  }

  @media screen and (max-width: $mediaWidthLG) {
    width: 100%;
    flex-direction: row;
    margin-bottom: 24px;
  }

  a {
    @include font_P2_reg($primary-100);
    padding: 12px 0;
    display: flex;
    align-items: center;
    text-decoration: none;

    @media screen and (max-width: $mediaWidthLG) {
      padding: 0;
    }

    .ant-tooltip--wrapper {
      display: inline-flex;
      align-items: center;

      @media screen and (max-width: $mediaWidthLG) {
        margin-right: 20px;
      }
    }

    .react-custom-icon {
      margin-right: 8px;
      color: $primary-100 !important;

      @media screen and (max-width: $mediaWidthLG) {
        margin-right: 0;
      }
    }

    .icon-section {
      .react-custom-icon {
        &:first-child {
          display: block !important;
        }
        &:last-child {
          display: none !important;
        }
      }
    }

    &:first-child {
      padding: 8px 0 12px;

      @media screen and (max-width: $mediaWidthLG) {
        padding: 0;
      }
    }

    &:hover,
    &.active {
      @include font_P2_reg($blue-100);

      .icon-section {
        .react-custom-icon {
          &:first-child {
            display: none !important;
          }
          &:last-child {
            display: block !important;
          }

          svg {
            color: $blue-100;
          }
        }
      }
    }

    &.active {
      @include font_T5($blue-100);
    }

    @media screen and (max-width: $mediaWidthLG) {
      span {
        display: none;
      }
    }
  }
}
