@import '../../../../styles/variables';

.next-payment {
  position: absolute;
  z-index: 20;
  top: 24px;
  right: 24px;
  margin-left: 24px;
  box-sizing: border-box;

  &-inside {
    background-color: $primary-05;
    max-width: 362px;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid $primary-10;

    &-title {
      display: flex;
      align-items: center;
      column-gap: 8px;
      @include font_P1_med($primary-100);

      span {
        @include font_P1_med($red-100);
      }
    }

    &-desc {
      margin-top: 12px;
      margin-bottom: 16px;
      @include font_P2_reg($primary-100);
    }

    &-actions {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  .ant-btn.dark {
    background-color: $primary-05 !important;
    border-color: $primary-100 !important;

    &:disabled {
      color: $primary-40 !important;
      border-color: $primary-10 !important;
    }
  }

  @media (max-width: $mediaWidthMD) {
    top: calc(50% - 102px);
    width: 100%;
    display: flex;
    justify-content: center;
    right: 0;
    margin-left: 0;
    padding: 0 24px;
  }
}
