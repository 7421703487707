@import 'src/styles/variables';

.ongoing-consultation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  background-color: $green-100;
  padding: 8px 24px;
  box-sizing: border-box;

  &.appointment-soon {
    background-color: $orange-100;
  }

  &[hidden] {
    display: none;
  }

  &--message {
    @include font_P2_reg($white);

    b {
      @include font_P2_semibold($white);
    }

    .ant-statistic {
      display: inline-flex;

      .ant-statistic-content {
        display: inline-flex;

        &-value {
          @include font_P2_semibold($white);
        }
      }
    }
  }

  &--action {
    display: inline-flex;
  }
}
