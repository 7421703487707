@import '../../../styles/variables';

.personal-data-page {
  .content-inside {
    display: flex;
    column-gap: 56px;
    margin-top: 16px;
    max-width: 100%;

    @media screen and (max-width: $mediaWidthXLL) {
      column-gap: 24px;
    }

    @media screen and (max-width: $mediaWidthXL) {
      flex-direction: column;
    }

    .personal-data {
      width: 100%;
    }

    .profile-title {
      margin-top: 16px;
      margin-bottom: 16px;
      @include font_T3_bold;
      line-height: 24px;
    }

    .user-view,
    .user-edit {
      width: 100%;
      position: relative;

      .profile-row {
        display: flex;
        column-gap: 24px;
        flex-direction: column;

        //@media screen and (max-width: $mediaWidthXLL) {
        //@media screen and (max-width: 1649.98px) {
        //  flex-direction: column;
        //}
      }

      .form-top-actions {
        display: flex;
        justify-content: flex-end;
        column-gap: 24px;
        position: absolute;
        right: 0;

        //@media screen and (max-width: $mediaWidthXLL) {
        //@media screen and (max-width: 1649.98px) {
        //
        //}
      }
    }
  }
}
