@import '../../../../styles/variables';

.user-image {
  margin: 0 auto;
  width: 190px;
  height: 120px;

  &-inside {
    position: relative;
    margin: 0 auto;
    line-height: 0;
    width: 185px;

    @media screen and (min-width: $mediaWidthXLL) {
      width: 208px;
    }
    @media screen and (min-width: $mediaWidthMax) {
      width: 232px;
    }

    img {
      width: 185px;
      height: 185px;
      border-radius: 50%;

      @media screen and (min-width: $mediaWidthXLL) {
        width: 208px;
        height: 208px;
      }
      @media screen and (min-width: $mediaWidthMax) {
        width: 232px;
        height: 232px;
      }
    }

    .no-image {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 183px;
      height: 183px;
      border: 1px solid $primary-40;

      @media screen and (min-width: $mediaWidthXLL) {
        width: 206px;
        height: 206px;
      }
      @media screen and (min-width: $mediaWidthMax) {
        width: 228px;
        height: 228px;
      }

      svg {
        width: 128px;
        height: 128px;
        color: $primary-40;

        @media screen and (min-width: $mediaWidthXLL) {
          width: 144px;
          height: 144px;
        }
        @media screen and (min-width: $mediaWidthMax) {
          width: 160px;
          height: 160px;
        }
      }
    }

    .actions-section {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;

      .react-custom-icon,
      button {
        margin: 0 4px;
      }

      .react-custom-icon {
        color: $primary-100 !important;

        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }
  }
}

