@import '../../styles/variables';

.inactive-profile {
  &-inside {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    height: calc(var(--app-height) - 34px);

    @media screen and (min-width: $mediaWidthXLL) {
      height: calc(var(--app-height) - 37px);
    }
    @media screen and (min-width: $mediaWidthMax) {
      height: calc(var(--app-height) - 40px);
    }

    .logo-section {
      width: 100%;
      text-align: center;
      margin-bottom: 64px;

      img {
        @media screen and (min-width: $mediaWidthXLL) {
          width: 164px;
        }
        @media screen and (min-width: $mediaWidthMax) {
          width: 182px;
        }
      }
    }
  }

  &-content {
    margin: 0 24px;
    text-align: center;
    max-width: 421px;

    @media screen and (min-width: $mediaWidthXLL) {
      max-width: 500px;
    }
    @media screen and (min-width: $mediaWidthMax) {
      max-width: 600px;
    }

    .subtitle {
      @include font_T4_sb;
    }

    .text {
      margin-top: 16px;
      @include font_P2_reg;
    }
  }
}
