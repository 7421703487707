@import "src/styles/variables";

.notifications {
  display: flex;
  background-color: $white;
  flex-direction: column;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;

  &--loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
  }

  &--header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-title {
      @include font_H1;
      margin-right: 10px;
    }

    .header-action {
    }

    .expert-auth-widget-navigation--item.menu-list {
      top: 130px;
    }
  }

  &--empty {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 47px - 32px);

    .empty-title {
      @include font_P1_med($primary-80);
      margin-top: 8px;
    }

    .empty-img {
      flex: 1 0 auto;
      margin-top: 24px;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      img {
        display: inline-flex;
        width: 100%;
        max-width: 499px;
      }
    }
  }

  &--list {
    margin-top: 24px;
    padding-right: 10px;
    height: calc(100% - 32px);

    @media (min-width: $mediaWidthXLL) {
      height: calc(100% - 35px);
    }

    @media (min-width: $mediaWidthMax) {
      height: calc(100% - 39px);
    }

    overflow-x: hidden;
    overflow-y: auto;

    .list-date--label {
      @include font_P3_reg($primary-60);
      margin-bottom: 8px;

      &:not(:first-child) {
        margin-top: 32px;
      }
    }

    .list-date--notifications {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;

      .notification-item {
        margin-top: 16px;
        margin-right: 24px;
        max-width: calc(50% - 12px);
        width: 100%;

        &:nth-child(-n + 2) {
          margin-top: 8px;
        }

        &:nth-child(even), &:last-child {
          margin-right: 0;
        }
      }

      @media (max-width: $mediaWidthX) {
        flex-wrap: nowrap;
        flex-direction: column;

        .notification-item {
          margin-top: 16px;
          max-width: 100%;
          margin-right: 0;

          &:nth-child(-n + 2) {
            margin-top: 16px;
          }

          &:nth-child(-n + 1) {
            margin-top: 8px;
          }
        }
      }
    }
  }

  @media (max-width: $mediaWidthMD) {
    &--header {
      //.header-title {
      //  display: none;
      //}
      //
      //.header-action {
      //  width: 100%;
      //  display: flex;
      //  justify-content: flex-end;
      //}
    }

    &--empty {
      .empty-img {
        flex: 0 0 auto;
      }
    }
  }
}
