@import "src/styles/variables";

.right-menu {
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  max-width: 105px;
  height: var(--app-height);
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background-color: $primary-02;
  padding: 24px;

  @media (min-width: $mediaWidthXLL) {
    max-width: 118px;
  }

  @media (min-width: $mediaWidthMax) {
    max-width: 131px;
  }

  &-header {
    margin-bottom: 16px;
    display: inline-flex;
    justify-content: center;

    &.stay-with-us {
      .icon-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border: 1px solid transparent;
        box-sizing: border-box;
        cursor: pointer;
        border-radius: 50%;
        transition: all 0.3s ease;

        .react-custom-icon {
          color: $primary-100 !important;
        }

        &:hover {
          border: 1px solid $primary-100;
        }
      }
    }
  }

  &-body {
    flex: 1 0 auto;
  }

  &-footer {
    width: 100%;
    display: inline-flex;
    justify-content: center;

    .react-custom-icon-big {
      display: inline-flex;

      div {
        display: inline-flex;
      }
    }

    .react-custom-icon-big, .react-custom-icon-big svg {
      cursor: pointer;
      width: 73px;

      @media screen and (min-width: $mediaWidthXLL) {
        width: 82px;
      }
      @media screen and (min-width: $mediaWidthMax) {
        width: 92px;
      }
    }
  }
}
