@import 'src/styles/variables';

.transactions-info-modal {
  div[role="dialog"] {
    width: 95vw !important;
    max-width: 604px;

    .ant-modal-content .ant-modal-header .ant-modal-title {
      @include font_T1;
    }

    .ant-modal-header {
      margin-bottom: 8px !important;
    }
  }

  &--body {
    display: inline-flex;
    flex-direction: column;

    .body-item {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid $primary-15;

      &--transaction {
        flex-direction: row;
        display: inline-flex;
        align-items: center;
        margin-bottom: 24px;
        padding: 8px 16px;
        background-color: $primary-05;
        border-radius: 32px;
        border-bottom: none;

        .transaction-item {
          display: inline-flex;
          align-items: center;
          @include font_P2_med;

          &.has-divider::after {
            content: '|';
            font-size: 12px;
            line-height: 20px;
            width: 16px;
            text-align: center;
            margin: 0 4px;
          }

          .react-custom-icon {
            display: inline-flex;
            margin-right: 4px;
            color: $orange-100 !important;
          }
        }

        .transaction-item:nth-child(2) {
          @include font_P3_reg($primary-80);
        }

        .transaction-item:nth-child(1) {
          @include font_P2_semibold;
          text-transform: capitalize;
        }
      }

      &--label {
        @include font_P2_semibold;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        text-transform: capitalize;

        .label-date {
          @include font_P4_med;
          margin-left: 8px;
        }
      }

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
}
