@import 'src/styles/variables';

.book-appointment {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-right: 10px;

  &--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;
    padding-bottom: 8px;
    border-bottom: 1px solid $primary-10;

    .header-item {
      &.item-title {
        @include font_T3_bold($blue-100);
        line-height: 31px;
      }

      &.item-actions {
        .ant-form-item {
          margin-bottom: 0;
        }
      }
    }
  }

  &--body {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .body-row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &.flex-items {
        display: flex;
        align-items: flex-start;
        gap: 24px;
      }

      .row-item {
        display: inline-flex;
        justify-content: center;
        width: calc(50% - 12px);
        flex: 1 1 280px;

        .form-field-wrapper, .day-availability-select, .custom-textarea, .ant-form-item {
          margin: 0;
          width: 100%;
          max-width: unset;

          .ant-input-affix-wrapper {
            box-shadow: none !important;
            outline: none !important;

            &-focused {
              box-shadow: none !important;
              outline: none !important;
            }
          }

          textarea {
            box-shadow: none;
            outline: none;
            border-radius: 12px;
          }
        }

        .custom-textarea {
          margin-bottom: 16px;
          outline: none;

          &:hover {
            .ant-input-affix-wrapper.ant-input-disabled {
              pointer-events: none;
            }
          }
        }

        &.calendar-item {
          .ant-form-item {
            width: auto;
          }
        }
      }

      &:last-child {
        .row-item {
          width: auto;
          justify-content: flex-end;
        }
      }
    }
  }
}

