@import '../../styles/variables';

.static-text-modal {
  .modal-body-item {
    overflow: auto;
    padding-right: 24px;
    height: calc(100vh - 250px);
    //max-height: 700px;
    //min-height: 700px;

    @media (max-width: $mediaWidthMD) {
      max-height: 400px;
      min-height: 400px;
    }
  }

  .ant-modal-footer {
    justify-content: center;
  }

  .ant-modal-content {
    max-width: 920px;

    .ant-modal-close {
      .react-custom-icon {
        color: $primary-100 !important;
      }
    }
  }
}
