@import "src/styles/variables";

.messages {
  box-sizing: border-box;
  height: 100%;

  @media (max-width: $mediaWidthMD) {
    padding: 24px 16px;
  }
}

