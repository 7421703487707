@import "src/styles/variables";

.active-appointments-queue {
  position: absolute;
  right: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 20;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  box-sizing: border-box;

  &.visible {
    visibility: visible;
    opacity: 1;
  }
}
