@import 'src/styles/variables';

.expert-about {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-right: 8px;

  &.full-height-page {
    height: 100%;
  }

  &--empty {
    margin-top: 46px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    .empty-img {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      img {
        display: inline-flex;
        width: 100%;
        max-width: 460px;
      }
    }

    .empty-subtitle {
      margin-top: 24px;
      @include font_sh_1($primary-80);
    }
  }

  &--item:nth-child(even) {
    margin-right: 56px;
  }

  &--item {
    width: calc(50% - 28px);
    margin-bottom: 32px;

    &:empty {
      display: none;
    }

    &.counsellor-actions {
      display: none;
      align-items: center;
      justify-content: space-around;
      gap: 16px 24px;
      margin-bottom: 24px;

      @media (max-width: $mediaWidthMD) {
        display: flex;
      }

      .ant-tooltip--wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;

        .expert-profile-menu--price {
          @include font_P4_reg($primary-60);
          display: inline-flex;
          align-items: center;
          margin-top: 4px;

          span.bold-text {
            @include font_P3_semibold;
            font-weight: 700 !important;
            margin-right: 2px;
          }
        }
      }

      &--item {
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        cursor: pointer;

        &.item-bg {
          background: $blue-100;
          transition: background-color 0.3s ease;

          &.item-active {
            background: $orange-100;
          }

          &:not(.disabled-btn):hover {
            background: $primary-10;
            color: $primary-100;

            .react-custom-icon {
              color: $primary-100 !important;
            }
          }

          &:active {
            .react-custom-icon {
              color: $primary-60 !important;
            }
          }

          .react-custom-icon {
            color: $white !important;
          }
        }

        &.disabled-btn {
          background: transparent;

          &.item-active, &:hover {
            cursor: inherit;
            background: transparent;
          }

          .react-custom-icon {
            color: $primary-20 !important;
          }
        }
      }
    }

    &.item-about {
      width: 100%;
    }

    &.item-specialisations {
      width: 100%;
    }

    &.counsellor-photo {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.split-items {
      .item-label:nth-child(3) {
        margin-top: 32px;
      }
    }

    .item-label {
      @include font_T3_bold;
      margin-bottom: 12px;
    }

    .item-content {
      @include font_P2_reg($primary-90);

      &--counsellor-photo {
        display: inline-flex;
        justify-items: center;
        position: relative;
        width: 100%;
        max-width: 185px;
        height: auto;
        max-height: 185px;

        img, .no-image {
          margin: 0 auto;
          width: 100%;
          max-width: 185px;
          height: auto;
          max-height: 185px;
          border-radius: 50%;
          background-color: $primary-10;

          @media screen and (min-width: $mediaWidthXLL) {
            width: 208px;
            height: 208px;
          }

          @media screen and (min-width: $mediaWidthMax) {
            width: 232px;
            height: 232px;
          }
        }

        .counsellor-badge {
          position: absolute;
          display: inline-flex;
          bottom: 0;
          right: 0;

          &.avatar-badge-worker_online {
            .react-custom-icon {
              color: $green-100 !important;
            }
          }

          &.avatar-badge-worker_offline, &.avatar-badge-deactivated {
            .react-custom-icon {
              color: $primary-60 !important;
            }
          }

          &.avatar-badge-worker_busy {
            .react-custom-icon {
              color: $yellow-100 !important;
            }
          }
        }
      }

      &--country {
        display: flex;
        align-items: center;

        img {
          margin-right: 8px;
          width: 24px;

          @media screen and (min-width: $mediaWidthXLL) {
            width: 27px;
          }

          @media screen and (min-width: $mediaWidthMax) {
            width: 30px;
          }
        }
      }

      &--about {
        white-space: break-spaces;
        word-break: break-word;
      }

      &--languages {
        .ant-tag.custom-tag {
          @include font_P3_reg;
          height: 24px;
          margin-top: 4px;

          @media screen and (min-width: $mediaWidthXLL) {
            height: 27px;
          }

          @media screen and (min-width: $mediaWidthMax) {
            height: 29px;
          }
        }
      }

      &--specializations {
        .specializations-item {

          &--label {
            @include font_P2_med;
            margin-bottom: 8px;
          }

          &--list {
            display: flex;
            flex-direction: column;
            margin-bottom: 12px;

            .list-item {
              @include font_P2_reg;
              display: inline-flex;
              align-items: center;
              margin-bottom: 12px;

              .react-custom-icon {
                display: inline-flex;
                color: $orange-100 !important;
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          &:last-child {
            .specializations-item--list {
              margin-bottom: 0;
            }
          }
        }


        //ul {
        //  list-style: none;
        //
        //  li::before {
        //    content: "\2022";
        //    color: $orange-100;
        //    font-weight: bold;
        //    font-size: 16px;
        //    display: inline-block;
        //    width: 20px;
        //    margin-left: -20px;
        //  }
        //}
      }

      &--educations {
        .educations-item {
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }

          &--date {
            @include font_P3_reg($primary-80);
            margin-bottom: 2px;
          }

          &--title {
            @include font_P2_med;
            margin-bottom: 2px;
          }

          &--subtitle {
            @include font_P3_reg($primary-60);
          }
        }
      }
    }
  }

  @media (max-width: $mediaWidthMD) {

    &--item:nth-child(even) {
      margin-right: 0;
    }

    &--item {
      width: 100%;
    }
  }
}
