@import 'src/styles/variables';

.message-item {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 16px;

  &.show-active {
    .item-info {
      &--body {
        background-color: $orange-05;
      }
    }
  }

  &.my-message {
    flex-direction: row-reverse;

    .item-avatar {
      margin-right: 0;
      margin-left: 8px;
    }

    .item-info--header {
      justify-content: flex-end;
    }

    .item-info--body {
      border-radius: 12px 1px 12px 12px;
      align-self: flex-end;
    }
  }

  &:last-child {
    margin-top: 0;
  }

  .item-avatar {
    margin-right: 8px;
  }

  .item-info {
    display: flex;
    flex-direction: column;

    &--header {
      display: inline-flex;
      align-items: center;
      margin-bottom: 8px;

      .header-date {
        @include font_P3_reg($primary-60);
        margin-right: 16px;
        white-space: nowrap;

        @media (max-width: $mediaWidthSM) {
          margin-right: 4px;
        }
      }

      .header-type {
        display: inline-flex;
        align-items: center;

        span {
          @include font_P2_reg;

          b {
            font-weight: 600;
          }
        }

        .react-custom-icon {
          display: inline-flex;
          color: $orange-100 !important;
        }
      }

      .header-actions {
        display: inline-flex;
        align-items: center;
        margin-right: 24px;

        &--item {
          width: 36px;
          height: 36px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: $blue-100;
          cursor: pointer;
          box-sizing: border-box;

          &.loader-item {
            background-color: transparent;
          }

          .react-custom-icon {
            color: $white !important;
          }
        }
      }

      .header-price {
        @include font_P2_med;
        display: inline-flex;
        align-items: center;

        &::before {
          content: '|';
          font-size: 12px;
          width: 16px;
          text-align: center;
          margin: 0 4px;
        }
      }
    }

    &--body {
      padding: 12px 16px;
      border: 1px dashed $orange-100;
      border-radius: 1px 12px 12px 12px;
      align-self: flex-start;

      .body-message {
        @include font_P1_reg;
        max-width: 504px;
        white-space: pre-line;
        word-break: break-word;

        a {
          @include font_P1_reg_und;
        }
      }

      .body-files {
      }

      @media (max-width: $mediaWidthMD) {
        .body-message {
          max-width: 100%;
        }
      }
    }
  }
}
