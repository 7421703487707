@import "src/styles/variables";

.counsellor-layout {
  @include loaderStyles;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &--loader {
    height: 100% !important;
  }

  &--header {
    margin-bottom: 24px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &--content {
    display: flex;
    box-sizing: border-box;
    padding: 0 24px 24px 24px;
    height: 100%;
    overflow: hidden;

    @media (max-width: 768px) {
      padding: 24px;
    }

    .content-aside {
      min-width: 185px;
      margin-right: 24px;

      @media (min-width: $mediaWidthXLL) {
        min-width: 212px;
      }
    }

    .content-body {
      width: calc(100% - 185px);
      height: 100%;
      overflow-y: auto;

      .messages {
        padding-bottom: 0;
      }

      @media (min-width: $mediaWidthXLL) {
        width: calc(100% - 212px);
      }

      @media (max-width: $mediaWidthLG) {
        width: 100%;

        .messages {
          padding-top: 0;
        }
      }
    }
  }

  @media (max-width: $mediaWidthLG) {
    &--content {
      flex-direction: column;

      .content-aside {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
