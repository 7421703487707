@import 'src/styles/variables';

.auth {
  position: relative;
  min-height: var(--app-height);
  display: flex;
  flex-direction: column;

  .card-section {
    width: 394px;
    box-sizing: border-box;

    @media (min-width: $mediaWidthXLL) {
      width: 448px;
    }

    @media (max-width: 440px) {
      width: 360px;
    }

    @media (max-width: 400px) {
      width: 312px;
    }
  }
}
