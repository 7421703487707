@import '../../../styles/variables';

.payments {
  .content-inside {
    max-width: 100%;

    .payment-methods {
      margin-bottom: 24px;
    }

    .add-payment {
      max-width: 394px;

      @media screen and (max-width: $mediaWidthSM) {
        max-width: 100%;
      }
    }
  }
}
