@import 'src/styles/variables';

.total-info-item {
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid $primary-20;

  &:last-child {
    border-bottom: none;
  }

  &--icon {
    display: inline-flex;
    margin-right: 8px;

    .react-custom-icon {
      color: $orange-100 !important;
    }
  }

  &--info {
    flex: 1 0 auto;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .info-title {
      @include font_P1_reg;

      a {
        &:active, &:focus, &:hover, &:visited {
          color: $blue-100;
        }
      }
    }

    .info-duration {
      @include font_P3_reg($primary-60);
    }
  }

  &--total {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .total-price {
      @include font_P1_med;
    }

    .total-date {
      @include font_P3_reg($primary-60);
    }
  }
}


