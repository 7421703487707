@import "src/styles/variables";

.single-logo-block {
  display: flex;
  align-items: center;
  height: 32px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 196px;
    margin-left: 6px;
    color: $blue-100;
    @include font_T2($blue-100);

    @media screen and (min-width: $mediaWidthXLL) {
      max-width: 230px;
    }

    @media screen and (min-width: $mediaWidthMax) {
      max-width: 265px;
    }
  }

  svg {
    color: $blue-100;
  }
}
