@import "src/styles/variables";

.sidebar {
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  max-width: 314px;
  min-width: 314px;
  height: var(--app-height);
  overflow: hidden;
  background-color: $primary-02;
  transition: all 0.3s ease;

  @media screen and (min-width: $mediaWidthXLL) {
    max-width: 353px;
    min-width: 353px;
  }

  @media screen and (min-width: $mediaWidthMax) {
    max-width: 393px;
    min-width: 393px;
  }

  &.close-sidebar {
    background-color: $primary-05;
    max-width: 105px;
    min-width: 105px;

    .sidebar-header {
      flex-direction: column-reverse;

      .header-item:last-child {
        margin-bottom: 8px;
      }
    }
  }

  &-filters {
    padding: 18px 24px;
  }

  &-body {
    flex: 1 0 auto;

    .expert-list--body {
      overflow-y: auto;
      max-height: calc(var(--app-height) - 64px - 76px - 72px);
    }
  }

  &-footer {
  }
}
