@import "src/styles/variables";

.chat-input {
  position: relative;

  &--item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:last-child {
      margin-bottom: 0;
    }

    &--files {
      @include font_F2($primary-40);
      position: absolute;
      left: 45px;
      bottom: -20px;
    }

    &:first-child {
      margin-bottom: 34px;

      .ant-spin-spinning.loader-item {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
        margin-right: 0;
      }

      .react-custom-icon {
        cursor: pointer;
        margin-right: 8px;
        color: $primary-100 !important;
        transition: all 0.3s ease;

        &.send-input {
          margin-right: 0;
          margin-left: 8px;
        }

        &.disabled {
          color: $primary-20 !important;
          pointer-events: none;
        }
      }
    }

    .ant-input-affix-wrapper {
      border: none !important;
      box-shadow: none !important;
      outline: none !important;

      textarea {
        @include font_F1;
        border: 1px solid $primary-20 !important;
        border-radius: 12px !important;
        resize: none !important;
        padding: 15px 16px;

        &::placeholder {
          color: $primary-60 !important;
        }

        &::-webkit-scrollbar {
          width: 4px;
          border-radius: 2px;
          background-color: transparent;
        }

        &::-webkit-scrollbar-track {
          margin: 10px 0;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $primary-80;
          width: 4px;
          border: 1px solid $primary-80;
          border-radius: 2px;
        }
      }

      &-focused {
        border: none !important;
        box-shadow: none !important;
        outline: none !important;

        textarea {
          border: 1px solid $primary-100 !important;
        }
      }
    }

    .ant-input-data-count {
      @include font_F2($primary-40);
      margin-right: 16px;
    }
  }
}
