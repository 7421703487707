.forgot-password {
  .server-error {
    align-items: flex-start;
  }

  .form-bottom-actions {
    margin-top: 44px;
  }

  .form-bottom {
    display: flex;
    justify-content: space-between;
  }
}
