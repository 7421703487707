.reset-password {
  .email-icon {
    display: flex;
    justify-content: center;
  }

  .form-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
  }
}
