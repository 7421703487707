@import '../../../styles/variables';

.payments-types {
  position: relative;
  padding: 40px 24px 0;

  .title {
    position: relative;
    text-align: center;
    margin: 0 auto 32px;
    max-width: 1022px;
    @include font_H1($white);

    .back-icon {
      position: absolute;
      left: 0;

      svg {
        color: $white;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.75;
      }
    }
  }

  .inside-section {
    display: flex;
    justify-content: center;

    .payments-list {
      //width: 394px;
      width: 420px;

      @media (min-width: $mediaWidthXLL) {
        //width: 425px;
        width: 451px;
      }

      @media (min-width: $mediaWidthMax) {
        //width: 450px;
        width: 476px;
      }

      .payments-items {
        min-height: 434px;

        .item-per-minute {
          position: relative;
          background-color: $primary-80;
          text-align: center;
          padding: 21px 12px;
          border-radius: 1px;
          margin-bottom: 16px;
          @include font_P1_reg($white);

          span {
            @include font_Sum3($white);
            margin-right: 4px;
          }

          @media (max-width: 420px) {
            span {
              @include font_Sum4($white);
            }
          }

          .free-mark {
            @include font_P1_reg($white);
            padding: 4px;
            box-sizing: border-box;
            position: absolute;
            left: 0;
            top: 0;
            word-break: break-word;
            background-color: $green-100;
          }
        }
      }

      .actions-section {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        padding-bottom: 24px;

        .ant-btn-primary {
          border-color: $white !important;
          background-color: transparent !important;
        }

        .ant-btn.ant-btn-lg {
          height: 36px;
        }
      }

      .ant-radio-group {
        .ant-radio-wrapper {
          margin-bottom: 0 !important;

          &:hover .ant-radio-inner {
            border-color: $orange-100;
          }

          .ant-radio-inner {
            background-color: transparent;
          }

          .ant-radio.ant-radio-checked {
            .ant-radio-inner {
              background-color: transparent;
              border-color: $orange-100;
            }

            .ant-radio-inner::after {
              background-color: $orange-100;
            }

            &:after {
              border: 1px solid $orange-100;
            }
          }
        }
      }
    }
  }
}

.ant-radio-group.item-category {
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;

  @media screen and (max-width: $mediaWidthXS) {
    flex-direction: column;
  }

  .ant-radio-wrapper {
    @include font_P1_reg($color: $white);

    @media screen and (max-width: $mediaWidthXS) {
      margin-left: 4px;

      &:nth-child(2) {
        margin-top: 16px;
      }
    }

    &:first-child {
      margin-left: 4px;
      margin-right: 36px;

      @media screen and (max-width: $mediaWidthXS) {
        margin-bottom: 12px !important;
      }
    }

    span.ant-radio + * {
      padding-inline-start: 12px;
      padding-inline-end: 8px;
    }
  }
}

.ant-radio-group.item-per-session {
  display: flex;
  flex-direction: column;

  .radio-group-item {
    display: flex;
    align-items: center;
    background-color: $primary-80;
    padding: 21px 12px;
    border-radius: 1px;
    margin-bottom: 16px;

    &:hover {
      cursor: pointer;
      background-color: $primary-60;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-icon {
      margin-right: 16px;
    }

    &-info {
      flex: 1 0 auto;
      @include font_P1_reg($white);

      span {
        @include font_Sum3($white);
      }

      span.dash-space {
        font-weight: 400 !important;
        margin: 0 10px;
      }

      span.tooltip-span {
        position: relative;

        .ant-tooltip--wrapper {
          display: inline-flex;
          position: absolute;
          top: -5px;
          right: -10px;

          .react-custom-icon {
            display: inline-flex;
            color: $white !important;
          }
        }
      }
    }

    @media (max-width: 420px) {
      &-info {
        span {
          @include font_Sum4($white);
        }
      }
      &-icon {
        .react-custom-icon.icon-size-xll {
          width: 24px !important;
          height: 24px !important;;

          svg {
            width: 24px !important;;
            height: 24px !important;;
          }
        }
      }
    }
  }
}

.white-tooltip {
  .ant-tooltip-arrow {
    &::before {
      background: $white !important;
    }
  }

  .ant-tooltip-content {
    background: $white !important;

    .ant-tooltip-inner {
      background: $white !important;
      color: $primary-100 !important;
    }
  }
}
