@import '../../../styles/variables';

.notification-settings {

  .content-description {
    @include font_P2_reg($primary-90);
    margin-bottom: 16px;
  }

  .content-inside {
    max-width: 379px;

    .notification-item {
      background-color: transparent;
      padding: 0;
      display: flex;
      align-items: center;
      width: 98%;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      &--name {
        @include font_P2_reg($primary-80);
        display: flex;
        align-items: center;
        flex: 1 0 auto;
        margin-right: 10px;

        .react-custom-icon {
          color: $blue-100 !important;
          cursor: help;
          margin-left: 4px;
        }
      }

      &--action {
        .ant-form-item-control-input {
          min-height: 24px;

          .ant-form-item-control-input-content {
            display: inline-flex;
          }
        }

        .ant-form-item {
          margin-bottom: 0;

          .ant-switch.ant-switch-checked .ant-switch-handle::before {
            background-color: $orange-100 !important;
          }
        }
      }
    }
  }

  .notifications-error {
    display: flex;
    column-gap: 8px;
    margin-top: 16px;
    max-width: 376px;

    @media screen and (max-width: $mediaWidthSM) {
      max-width: 100%;
    }

    span {
      @include font_P2_reg($red-100);
    }
  }
}
