@import 'src/styles/variables';

.expert-profile-menu {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px 24px;

  &:empty {
    display: none;
  }

  &:has(.ant-tooltip--wrapper:only-child) {
    justify-content: flex-end !important;

    @media (max-width: $mediaWidthMD) {
      justify-content: flex-start !important;
    }
  }

  .item-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;

    &.free-minutes {
      @include font_P3_semibold;
      padding: 8px;
      align-self: stretch;
      display: inline-flex;
      justify-content: center;
      box-sizing: border-box;
      border: 1px solid $orange-100;
      border-radius: 4px;
      max-width: 85px;
      min-width: 85px;
      text-align: center;

      @media (min-width: $mediaWidthXLL) {
        max-width: 95px;
        min-width: 95px;
      }

      @media (min-width: $mediaWidthMax) {
        max-width: 105px;
        min-width: 105px;
      }
    }

    .ant-tooltip--wrapper {
      padding: 8px;
      border-radius: 50%;
    }

    .expert-profile-menu--price {
      @include font_P4_reg($primary-60);
      display: inline-flex;
      align-items: center;
      margin-top: 4px;

      .ant-tooltip--wrapper {
        display: inline-flex;
        align-items: center;
        flex-direction: row;
        padding: 0;
        border-radius: 0;
      }

      span.bold-text {
        @include font_P3_semibold;
        font-weight: 700 !important;
        margin-right: 2px;
      }
    }
  }

  &--item {
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.item-bg {
      background: $blue-100;
      transition: background-color 0.3s ease;

      &.book-a-call {
        background: $primary-100;
      }

      &.item-active {
        background: $orange-100;
      }

      &:not(.disabled-btn):hover {
        background: $primary-10;
        color: $primary-100;

        .react-custom-icon {
          color: $primary-100 !important;
        }
      }

      &:active {
        .react-custom-icon {
          color: $primary-60 !important;
        }
      }

      .react-custom-icon {
        color: $white !important;
      }
    }

    &.disabled-btn {
      background: transparent;

      &.item-active, &:hover {
        cursor: inherit;
        background: transparent;
      }

      .react-custom-icon {
        color: $primary-20 !important;
      }
    }
  }

  @media (max-width: $mediaWidthX) {
    justify-content: space-between;

    &:has(.ant-tooltip--wrapper:only-child) {
      justify-content: flex-start;
    }
  }

  //@media (max-width: $mediaWidthMD) {
  //  flex-wrap: wrap;
  //  gap: 16px 24px;
  //  box-sizing: border-box;
  //
  //  .ant-tooltip--wrapper {
  //    flex-basis: 80px;
  //    margin-right: 0;
  //  }
  //}

  //@media (max-width: $mediaWidthXS) {
  @media (max-width: $mediaWidthXL) {
    flex-wrap: wrap;
    gap: 16px;

    //.ant-tooltip--wrapper {
    //  margin-right: 6px;
    //}
  }
}
