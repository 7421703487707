@import "src/styles/variables";

.sidebar-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &-item:first-child {
    margin-right: 16px;
  }

  &-item {
    display: inline-flex;
    position: relative;
    cursor: pointer;

    .react-custom-icon {
      color: $primary-100 !important;
    }

    .form-field-wrapper {
      width: 100%;
    }

    .ant-checkbox-wrapper {
      white-space: nowrap;
    }

    &.sign-in {
      display: none;
      margin-left: 16px;
    }

    &.disabled-icon {
      cursor: not-allowed;
      color: $primary-100 !important;
      opacity: 0.3;
    }

    &.has-settings::after {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: 0;
      background-color: $orange-100;
    }

    &.item-menu {
      align-items: center;
      margin-left: 16px;

      &.open-menu {
        .react-custom-icon {
          transform: rotate(180deg);
        }
      }

      .react-custom-icon {
        cursor: pointer;
        transition: all 0.3s ease;
      }
    }

    &.menu-list {
      position: fixed;
      overflow: hidden;
      z-index: 10;
      width: 100%;
      top: calc(136px + var(--outgoingHeight));
      left: 0;
      height: var(--app-height);

      .right-menu {
        width: 100%;
        height: 100%;
        max-width: unset;

        &-body {
          justify-content: flex-start;

          .menu-list {
            justify-content: flex-start;

            .top-section, .center-section {
              .ant-tooltip--wrapper {
                margin-bottom: 16px !important;
              }
            }

            .center-section {
              flex: 0 0 auto;
              padding-bottom: 0;

              .ant-tooltip--wrapper:last-child {
                margin-bottom: 0 !important;
              }
            }

            .bottom-section {
              flex: 1 0 auto;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  @media (max-width: $mediaWidthMD) {
    .sidebar-filter-item:first-child {
      width: 100%;
      //flex: 1 0 auto;
    }
  }

  @media (max-width: $mediaWidthMD) {
    .sidebar-filter-item.sign-in {
      display: inline-flex;
    }
  }
}
