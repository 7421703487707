@import "src/styles/variables";

.history {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &.history-expert {
    .history-list {
      padding: 0;
    }

    .history--loader {
      background-color: transparent;
    }
  }

  @media (max-width: $mediaWidthMD) {
    background-color: $primary-02;
  }

  @include loaderStyles;

  &--loader {
    height: 100%;

    .spin-element.full-height {
      height: 100%;
    }
  }

  &-list {
    flex: 1 0 auto;
  }

  &--desktop {
    height: 100%;
    box-sizing: border-box;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;

    .desktop--body {
      flex: 1 0 auto;
      max-width: 395px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: $mediaWidthXLL) {
        max-width: 445px;
      }

      @media screen and (min-width: $mediaWidthMax) {
        max-width: 495px;
      }

      .body-title {
        @include font_F3;
        text-align: center;
        margin-bottom: 16px;
      }

      .body-actions {
        margin-top: 16px;
        display: flex;
        justify-content: center;
      }
    }
  }

  &--mobile {
    display: none;
    height: 100%;
    flex-direction: column;

    .history-base {
      padding: 16px 24px;

      .header-title {
        display: none;
      }

      .header-subtitle {
        @include font_P1_med($primary-80);
      }

      &--img {
        align-items: flex-start;
      }
    }

    .mobile-body {
      height: 100%;

      .expert-list--body {
        max-height: calc(var(--app-height) - 76px - 76px - 64px);
        overflow-y: auto;
      }
    }
  }

  @media (max-width: $mediaWidthMD) {
    &--desktop {
      display: none;
    }

    &--mobile {
      display: flex;
    }
  }
}
