@import "src/styles/variables";

.transactions {
  height: 100%;

  &.transactions-counsellor {
    height: 100%;

    .transactions-empty--inside {
      padding: 0;
    }

    .transactions-table--header, .transactions-list--header {
      padding: 0 0 16px 0 !important;
    }

    .transactions-list--pagination {
      padding: 16px 0 0 0 !important;

      @media (max-width: $mediaWidthXS) {
        .ant-pagination {

        }
      }
    }

    .transactions-table--body, .transactions-list--body {
      padding: 0;
    }
  }

  &--desktop {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &--mobile {
    height: 100%;
    display: none;
    flex-direction: column;
  }

  &--empty {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 1100px) {
    &--desktop {
      display: none;
    }

    &--mobile {
      display: flex;
    }
  }
}
