@import 'src/styles/variables';

.expert-status-notifier {
  display: flex;
  align-items: center;
  border-radius: 12px;
  background-color: $orange-05;
  padding: 12px 16px;
  border: 1px solid $orange-100;
  width: max-content;
  box-sizing: border-box;

  @media (max-width: $mediaWidthLG) {
    width: 100%;
  }

  &--icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;

    .react-custom-icon {
      display: inline-flex;
      color: $orange-100 !important;
    }
  }

  &--text {
    display: inline-flex;
    @include font_P3_reg;
  }
}
