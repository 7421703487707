@import "src/styles/variables";

.appointment-reminder {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 16px;
  background-color: $primary-100;
  border-radius: 12px;
  box-sizing: border-box;
  max-width: 394px;

  &--close {
    position: absolute;
    z-index: 10;
    right: 24px;
    top: 24px;
    cursor: pointer;
    display: inline-flex;

    &:hover {
      cursor: pointer;
      opacity: 0.75;
    }
  }

  &--avatar {
    display: inline-flex;
  }

  &--reminder {
    @include font_P1_reg($white);
    text-align: center;
  }

  &--actions {
    display: inline-flex;
  }
}
