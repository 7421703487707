@import '../../styles/variables';

.appointments {
  display: flex;
  flex-direction: column;
  height: var(--app-height);
  overflow: hidden;

  @media (max-width: $mediaWidthMD) {
    max-height: calc(var(--app-height) - 76px - 80px);
  }

  &--header {
    display: flex;
    align-items: center;
    padding: 16px 24px 24px 24px;
    gap: 12px;

    .header-title {
      @include font_H1;
      flex: 1 0 auto;
    }

    .header-search {
      width: 100%;
      max-width: 290px;
      margin-right: 24px;

      @media (max-width: $mediaWidthMD) {
        display: none;
      }
    }

    .header-filters {
      position: relative;
      display: inline-flex;
      cursor: pointer;

      &.has-filters::after {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: $orange-100;
      }

      .react-custom-icon {
        color: $primary-100 !important;
      }
    }
  }

  &--body {
    padding: 0 24px;
    height: 100%;
    overflow-y: auto;
  }
}

.cancel-appointment-item {
  @include font_P2_reg;
  display: flex;
  align-items: center;
  gap: 12px;

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}
