@import '../../../styles/variables';

.reset-password-resend {
  &-text {
    @include font_P1_reg;
    margin-bottom: 24px;
  }
  .text-after {
    @include font_P1_reg;

    span.send-again {
      color: $primary-60;
    }

    span.send-again.send-again-active {
      color: $blue-100;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
