@import "src/styles/variables";

.transactions-item {
  display: flex;
  padding: 16px;
  gap: 16px;
  border: 1px solid $primary-05;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  &--column {

    &:first-child {
      width: 100%;
    }

    .column-item {

      &--date {
        @include font_P3_reg($primary-80);
        display: inline-flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;

        span {
          display: inline-flex;

          &:after {
            content: ',';
            margin-right: 2px;
          }

          &:last-child, &:first-child {
            &::after {
              content: none;
            }
          }

          .react-custom-icon {
            color: $orange-100 !important;
            display: inline-flex;
            margin-right: 2px;
          }
        }

        .bold-text {
          @include font_P3_semibold($primary-80);
        }
      }

      &--actions {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 16px;

        .react-custom-icon {
          color: $primary-100 !important;
        }

        .ant-spin.actions-spin {
          display: inline-flex;
          align-items: center;
          height: 24px;
        }

        .ant-dropdown-trigger {
          display: inline-flex;
          cursor: pointer;

          & > * {
            display: inline-flex;
          }
        }
      }

      &--total {
        margin-left: auto;
        @include font_T4_sb($primary-80);
        min-width: 13px;
        align-self: flex-end;
      }
    }
  }

  &--footer {
    display: flex;
    align-items: center;
    margin-top: 4px;

    .footer-item {
      @include font_P2_reg($primary-80);

      &:first-child {
        flex: 1 0 auto;
      }

      .react-custom-icon {
        cursor: pointer;
      }

      &.accepted {
        color: $green-100;

        .react-custom-icon {
          color: $green-100 !important;
        }
      }

      &.declined {
        color: $red-100;

        .react-custom-icon {
          color: $red-100 !important;
        }
      }

      &.requested {
        color: $orange-100;

        .react-custom-icon {
          color: $orange-100 !important;
        }
      }
    }

  }
}
