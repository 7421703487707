@import '../../../../styles/variables';

.network-disconnected {
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &-inside {
    padding: 0 24px;
    max-width: 394px;
    width: 100%;
    box-sizing: border-box;

    .title {
      text-align: center;
      margin-bottom: 24px;
      @include font_H1($white);
    }

    .call-desc {
      text-align: center;
      margin-bottom: 8px;
      @include font_T2($white);
    }

    .call-result-info {
      padding: 8px 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      .react-custom-icon {
        margin-right: 8px;
      }

      .call-divider {
        width: 1px;
        height: 24px;
        background-color: $primary-60;
        margin: 0 20px;
      }

      .call-result-text {
        @include font_P1_med($white);
      }
    }

    .actions-section {
      text-align: center;
      margin-top: 24px;
    }
  }
}
