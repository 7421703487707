@import 'src/styles/variables';

.message-list--item {
  display: flex;
  align-items: flex-start;
  width: 100%;
  //margin-top: 16px;
  margin-bottom: 16px;

  &:last-child {
    //margin-top: 0;
    margin-bottom: 0;
  }

  .item-avatar {
    margin-right: 8px;
  }

  .item-info {
    &--date {
      @include font_P3_reg($primary-60);
      margin-bottom: 8px;
    }

    &--message {
      @include font_P1_reg;
      white-space: pre-line;
      word-break: break-word;

      a {
        @include font_P1_reg_und;
      }
    }
  }

  &.my-message {
    flex-direction: row-reverse;
    align-self: flex-end;

    .item-info--date {
      text-align: right;
    }

    .item-avatar {
      margin-right: 0;
      margin-left: 8px;
    }
  }
}
