@import "src/styles/variables";

.privacy-policy {
  height: 100%;
  box-sizing: border-box;
  background-color: $white;
  padding: 24px;

  &--header {
    display: flex;
    align-items: center;

    .header-title {
      @include font_H1;
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  &--body {
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    height: calc(100% - 31px - 24px);
    padding-right: 8px;
    margin-top: 24px;

    @media (min-width: $mediaWidthXLL) {
      height: calc(100% - 35px - 24px);
    }

    @media (min-width: $mediaWidthMax) {
      height: calc(100% - 39px - 24px);
    }

    p:first-child {
      margin-top: 0;
    }
  }
}
