@import "src/styles/variables";

.messages--body {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  &-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
  }

  &-empty {
    flex: 1 0 auto;
  }
}

