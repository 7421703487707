@import "src/styles/variables";

.appointments-mobile {
  position: relative;
  display: none;
  flex-direction: column;
  gap: 24px;

  @media (max-width: $mediaWidthX) {
    display: flex;
  }

  &--loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--empty {
    @include font_P2_reg($primary-80);
    text-align: center;
  }

  &--pagination {
    display: flex;
    justify-content: flex-end;
    order: 2;

    & + .appointments-mobile--list {
      max-height: calc(var(--app-height) - 160px);

      @media (max-width: $mediaWidthMD) {
        max-height: calc(var(--app-height) - 160px - 76px - 80px);
      }
    }
  }

  &--list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: calc(var(--app-height) - 110px);
    overflow: hidden;
    overflow-y: auto;
    padding-right: 5px;

    @media (max-width: $mediaWidthMD) {
      max-height: calc(var(--app-height) - 110px - 76px - 80px);
    }
  }
}
