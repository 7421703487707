@import '~@ppmcore/seven-ppm-core-shared-components-react/dist/assets/styles/index';

:root {
  --overHeight: '0px';
  --outgoingHeight: '0px';
}

body, html {
  height: 100%;

  #root {
    height: 100%;
  }
}

// Date Range Picker
@media (max-width: 700px) {
  .ant-picker-panels {
    max-width: 500px;
    overflow-x: scroll;
  }
}

@media (max-width: 600px) {
  .ant-picker-panels {
    max-width: 400px;
    overflow-x: scroll;
  }
}

@media (max-width: 500px) {
  .ant-picker-panels {
    max-width: 370px;
    overflow-x: scroll;
  }
}

@media (max-width: 420px) {
  .ant-picker-panels {
    max-width: 288px;
    overflow-x: scroll;
  }
}

.ant-picker-dropdown {
  @media (max-width: 700px) and (max-height: 740px) {
    min-width: unset !important;
    inset: calc(50% - 200px) auto auto calc(50% - 250px) !important;
  }
  @media (max-width: 600px) and (max-height: 740px) {
    inset: calc(50% - 200px) auto auto calc(50% - 200px) !important;
  }
  @media (max-width: 500px) and (max-height: 740px) {
    inset: calc(50% - 200px) auto auto calc(50% - 185px) !important;
  }
  @media (max-width: 400px) and (max-height: 740px) {
    inset: calc(50% - 200px) auto auto calc(50% - 144px) !important;
  }
}
