@import 'src/styles/variables';

.checkout-modal {
  overflow: hidden;

  &-dark {
    .ant-modal {
      &-content {
        background-color: $primary-100;

        .ant-modal-close {
          color: $white !important;

          .react-custom-icon {
            color: $white !important;
          }
        }

        .ant-modal-header {
          background: transparent;

          .ant-modal-title {
            color: $white;
          }
        }

        .ant-modal-body {

        }
      }
    }

    .checkout-modal--body {
      .body-item {
        &--divider {
          border-color: $primary-60;
        }
      }
    }
  }

  div[role="dialog"] {
    width: 1022px !important;

    .ant-modal-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      max-width: unset;
      min-height: 624px;
      transition: all 0.3s ease;

      .ant-modal-close {
        color: $primary-100;

        .react-custom-icon {
          color: $primary-100 !important;
        }
      }

      .ant-modal-header {

      }

      .ant-modal-body {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
      }
    }

    @media (max-height: 700px) {
      .ant-modal-content {
        min-height: calc(var(--app-height) - 60px);
      }
    }
  }

  &--body {
    display: flex;
    flex: 1 0 auto;

    .body-item {
      width: 50%;
      display: flex;

      .consultation-info {
        .stripe-footer {
          justify-content: flex-start !important;
        }
      }

      &--divider {
        border-right: 1px solid $primary-10;
        padding-right: 40px;
        margin-right: 40px;
        box-sizing: border-box;
      }

      &:last-child {
        width: calc(50% - 41px);
      }
    }
  }

  @media (max-width: $mediaWidthMD) {
    .ant-modal-content {
      padding: 15px;

      .ant-modal-body {
        max-height: calc(var(--app-height) - 28px - 30px - 100px);
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 8px;
      }
    }


    .checkout-modal--body {
      flex-direction: column;

      .body-item {
        width: 100%;
        display: block;

        .consultation-info {
          width: 100%;
        }

        .ant-form {
          max-width: unset;
        }

        &--divider {
          border-right: none;
          border-bottom: 1px solid $primary-10;
          padding-right: 0;
          padding-bottom: 40px;
          margin-right: 0;
          margin-bottom: 40px;
          box-sizing: border-box;
        }

        &:last-child {
          width: auto;
        }
      }
    }
  }
}
