@import "src/styles/variables";

.main-layout {

  &.layout-hidden {
    display: none;
  }

  &--desktop {
    display: flex;
    background: white;
    position: relative;

    .desktop-item {
      align-items: stretch;

      &--left-menu {
        background-color: $primary-02;
      }

      &--header-menu, &--bottom-menu {
        display: none;
      }

      &--content {
        overflow: hidden;

        //.content-header:not(:empty) + .content-container {
        //  height: calc(100% - 40px);
        //
        //  @media (min-width: $mediaWidthMax) {
        //    height: calc(100% - 41px);
        //  }
        //}

        .content-container {
          height: calc(100% - var(--outgoingHeight));
          background: $white;
          max-width: 1440px;
          margin: 0 auto;
        }

        // TODO max width 1440px
      }
    }

    .desktop-item:nth-child(2) {
      width: 100%;
      height: var(--app-height);
      position: relative;
    }

    @media (max-width: $mediaWidthMD) {
      .desktop-item--left-menu {
        width: 100%;

        .sidebar {
          max-width: unset;
        }
      }
    }
  }

  &--mobile {
    display: flex;
    height: var(--app-height);
    overflow: hidden;
    flex-direction: column;

    .mobile-item {
      &--left-menu, &--right-menu {
        display: none;
      }

      &--header-menu {
        order: -1;
      }

      &--content {
        height: calc(100% - 76px - 76px);

        .content-container {
          height: calc(100%);
          flex: 1 0 auto;
          background: $white;
          overflow-y: auto;
          padding-right: 3px;
        }

        .content-header:not(:empty) + .content-container {
          height: calc(100% - var(--outgoingHeight));

          .expert-list {
            margin-top: 16px;
          }

          .expert-list--body {
            max-height: unset;
          }

          //@media (max-width: 490px) {
          //  height: calc(100% - 76px);
          //}
          //
          //@media (max-width: 375px) {
          //  height: calc(100% - 96px);
          //}
        }
      }
    }
  }
}
