@import '../../../styles/variables';

.chat-stay-with-us-page {
  position: absolute;
  z-index: 9;
  background-color: $call-bg;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .chat-stay-with-us-page-inside {
    max-width: 394px;
    text-align: center;

    .chat-stay-with-us-title {
      @include font_H1($white);
    }

    .chat-stay-with-us-text {
      @include font_F1($primary-20);
      margin: 24px 0;
    }

    .chat-stay-with-us-btn-line {
      display: flex;
      justify-content: center;
      column-gap: 24px;
      margin-bottom: 24px;
    }
  }
}
