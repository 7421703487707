@import "src/styles/variables";

.appointments-desktop {
  @media (max-width: $mediaWidthX) {
    display: none;
  }

  &--table {
    th.ant-table-cell {
      text-align: center;
      padding: 8px 12px !important;

      &:not(.client) {
        .ant-table-column-sorters {
          justify-content: center !important;
        }
      }
    }

    .ant-table-row {
      &.awaiting {
        background-color: $blue-05 !important;

        &:hover {
          .ant-table-cell {
            background-color: $blue-05 !important;
          }
        }
      }
    }

    .ant-table-row td.ant-table-cell {
      padding: 16px 12px !important;

      &.date {
        div {
          @include font_P3_reg;
          display: inline-flex;
          flex-direction: column;

          b {
            @include font_P3_semibold;
          }
        }
      }

      &.client {
        @include font_P1_reg($blue-100);

        .client-wrapper {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 4px 16px;

          &--name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 4px;
            flex: 1 1 auto;

            .name-field {
              @include lineClamp(1);
              word-break: break-all;
            }

            .ant-tooltip--wrapper:not(.name-field) {
              display: inline-flex;
              cursor: pointer;
            }
          }
        }
      }

      &.type {

        .type-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          gap: 4px;

          &--icon {
            display: inline-flex;

            .react-custom-icon {
              color: $orange-100 !important;
            }
          }

          &--name {
            @include font_P3_semibold($primary-80);
          }
        }
      }

      &.duration {
        @include font_P3_reg;
      }

      &.cost {
        @include font_T4_sb;
      }

      &.status {

        .status-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 9px;

          &--point {
            display: inline-flex;
            min-width: 6px;
            width: 6px;
            min-height: 6px;
            height: 6px;
            background-color: $orange-100;
            border-radius: 50%;

            &.approved {
              background-color: $blue-100;
            }

            &.awaiting {
              background-color: $orange-100;
            }

            &.canceled {
              background-color: $red-100;
            }

            &.past {
              background-color: $primary-60;
            }
          }

          &--name {
            @include font_P3_reg($primary-80);
          }
        }
      }

      &.actions {

        .actions-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;

          &--item {
            display: inline-flex;
            cursor: pointer;

            &.cancel-type {
              .react-custom-icon {
                color: $red-100 !important;
              }
            }

            &.approve-type {
              .react-custom-icon {
                color: $green-100 !important;

                path {
                  fill: $green-100 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
