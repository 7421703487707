@import "src/styles/variables";

.logo-block {
  width: 100%;
  border: none !important;
  background: transparent !important;

  &--dropdown {
    min-width: 290px !important;
    max-width: 290px;

    .ant-dropdown-menu {
      max-height: 500px;
      overflow: hidden;
      overflow-y: auto;
      padding: 0 !important;
    }

    .ant-dropdown-menu-item {
      padding: 12px 16px !important;
      box-sizing: border-box;
      border-bottom: 1px solid $primary-05;

      &:last-child {
        border-bottom: none;
      }

      .ant-dropdown-menu-item-icon {
        margin-left: 0 !important;
        color: $blue-100 !important;
      }

      .ant-dropdown-menu-title-content {
        .company-name {
          @include font_P1_med;
          @include lineClamp(1);
          max-width: 190px;
          word-break: break-all;
          white-space: break-spaces;
        }

        .react-custom-icon {
          margin-left: auto;
        }
      }
    }
  }

  &--trigger {
    display: flex;
    align-items: center;
    height: 32px;
    width: 100%;
    cursor: pointer;

    .view-tooltip {
      display: flex;
      align-items: center;

      span {
        @include font_T2($blue-100);
        @include lineClamp(1);
        word-break: break-all;
        white-space: break-spaces;
        max-width: 178px;
        margin-left: 6px;
        color: $blue-100;

        @media screen and (min-width: $mediaWidthXLL) {
          max-width: 200px;
        }

        @media screen and (min-width: $mediaWidthMax) {
          max-width: 230px;
        }
      }

      .react-custom-icon {
        margin-left: 0 !important;
        color: $blue-100 !important;
      }
    }

    .view-arrow {
      margin-left: auto;
      transition: all 0.3s ease-in-out;

      &.rotate-icon {
        transform: rotate(180deg);
      }
    }
  }

  .ant-dropdown-menu-title-content {
    .react-custom-icon {
      margin-left: 10px;
    }
  }
}
