@import "src/styles/variables";

.messages--body-list {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  max-height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 4px;
  height: 100%;

  .body-list-item {

    &--today {
      @include font_P4_reg($primary-80);
      display: flex;
      white-space: nowrap;
      align-items: center;
      width: 100%;
      margin: 32px 0 16px 0;

      &:before, &:after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: $primary-15;
      }

      &:before {
        margin-right: 14px;
      }

      &:after {
        margin-left: 14px;
      }
    }
  }

  .history-notification {
    margin-top: 32px;
  }

  .info-message {
    margin-top: 16px;
    max-width: 520px;
    margin-left: auto;
    margin-right: 44px;

    &:last-child {
      margin-top: 0;
    }
  }

  .spin-element {
    position: fixed;
    left: 0;
    top: 0;
  }

  @media (max-width: $mediaWidthMD) {
    .info-message {
      max-width: 100%;
      margin-right: 0;
    }
  }
}

