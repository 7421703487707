@import '../../styles/variables';

.page-not-found {
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: $mediaWidthXS) {
    .footer {
      position: relative;
    }
  }

  &-inside {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px;
    box-sizing: border-box;
    flex: 1 0 auto;

    .logo-section {
      width: 100%;
      text-align: center;
      margin-bottom: 28px;

      img {
        @media screen and (min-width: $mediaWidthXLL) {
          width: 164px;
        }
        @media screen and (min-width: $mediaWidthMax) {
          width: 182px;
        }
      }
    }
  }

  &-content {
    text-align: center;
    padding: 0 15px;

    .title {
      font-family: "Noto Sans", sans-serif !important;
      font-style: normal;
      font-weight: 600 !important;
      font-size: 128px !important;
      line-height: 148px;
      color: $primary-80;

      @media screen and (min-width: $mediaWidthXLL) {
        font-size: 132px !important;
        line-height: 152px;
      }

      @media screen and (min-width: $mediaWidthMax) {
        font-size: 136px !important;
        line-height: 156px;
      }
    }

    .subtitle {
      margin-top: 16px;
      margin-bottom: 4px;
      @include font_T4_sb()
    }

    .text {
      margin-bottom: 40px;
      @include font_P1_reg()
    }
  }
}
