@import '../../../styles/variables';

.free-minutes-countdown {
  //@include font_T2($white);
  display: flex;
  align-items: center;

  &--text, .price {
    @include font_T2($white);
  }

  span {
    width: 1px;
    height: 22px;
    margin: 0 20px;
    background-color: $primary-60;

    @media screen and (max-width: $mediaWidthXS) {
      margin: 0 12px;
    }
  }

  &.red-time {
    .free-minutes-countdown--text {
      @include font_T2($red-100);
    }
  }
}
