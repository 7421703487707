@import "src/styles/variables";

.sidebar-menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: $primary-05;
  padding: 14px;

  &.temp-sidebar-menu {
    justify-content: space-between;
  }

  &.menu-vertical {
    flex-direction: column;
    max-width: 105px;
  }

  &-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border: 1px solid transparent;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.3s ease;

    .react-custom-icon {
      display: inline-flex;
      cursor: pointer;
      color: $primary-100 !important;
    }

    &.active-item {
      border-radius: 50%;
      border: 1px solid $primary-100;

      .react-custom-icon {
        color: $primary-100 !important;
      }
    }

    &.disabled-item {
      .react-custom-icon {
        opacity: 0.4;
      }
    }

    &:not(.active-item):not(.disabled-item):hover {
      border: 1px solid $primary-100;
    }
  }

  &.menu-vertical {
    .sidebar-menu-item-tooltip {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
