@import '../../../../styles/variables';

.subscriber-audio, .subscriber-video {
  &.wrapper-hidden {
    opacity: 0;
  }
}

.subscriber-video {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;

  .OT_widget-container {
    background: transparent !important;
  }

  .OT_subscriber {
    min-width: 100%;
    min-height: calc(100vh - 154px);
    height: calc(100vh - 154px);

    @media screen and (max-width: $mediaWidthMD) {
      min-height: calc(100vh - 192px);
      height: calc(100vh - 192px);
    }
  }

  .OT_edge-bar-item,
  .OT_audio-level-meter,
  .OT_video-disabled-indicator,
  .OT_audio-blocked-indicator,
  .OT_video-poster,
  .OT_video-loading {
    display: none !important;
  }

  .subscriber-section {
    position: relative;
    z-index: 2;
  }

  .subscriber-fio {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 12px;
    left: 16px;
    z-index: 5;
    @include font_P3_semibold($white);

    svg {
      margin-left: 4px;
    }
  }

  .subscriber-image {
    position: absolute;
    width: 140px;
    height: 140px;
    left: calc(50% - 70px);
    top: calc(50% - 70px);

    @media screen and (min-width: $mediaWidthXLL) {
      width: 158px;
      height: 158px;
      left: calc(50% - 79px);
      top: calc(50% - 79px);
    }
    @media screen and (min-width: $mediaWidthMax) {
      width: 175px;
      height: 175px;
      left: calc(50% - 87px);
      top: calc(50% - 87px);
    }

    img {
      width: 140px;
      height: 140px;
      border-radius: 50%;

      @media screen and (min-width: $mediaWidthXLL) {
        width: 158px;
        height: 158px;
      }
      @media screen and (min-width: $mediaWidthMax) {
        width: 175px;
        height: 175px;
      }
    }

    .react-custom-icon-big, .react-custom-icon-big svg {
      width: 140px;
      height: 140px;

      @media screen and (min-width: $mediaWidthXLL) {
        width: 158px;
        height: 158px;
      }
      @media screen and (min-width: $mediaWidthMax) {
        width: 175px;
        height: 175px;
      }
    }

    &.voice-active {
      img, .default-image {
        box-shadow: 0 0 30px 0 rgba(255, 255, 255, 0.90);
      }
    }
  }
}

.subscriber-audio {
  .OT_widget-container,
  .OT_edge-bar-item,
  .OT_audio-level-meter,
  .OT_video-disabled-indicator,
  .OT_audio-blocked-indicator,
  .OT_video-poster {
    display: none !important;
  }

  .OT_subscriber {
    min-width: 0;
    min-height: 0;
  }

  .subscriber-fio {
    min-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include font_P3_semibold($white);

    &--name {
      @include font_P3_semibold($white);
      @include lineClamp(1);
      word-break: break-all;
      white-space: break-spaces;
      max-width: 136px;
    }

    svg {
      margin-left: 8px;
    }
  }

  .subscriber-image {
    padding: 12px;
    width: 140px;
    height: 140px;
    left: calc(50% - 70px);
    top: calc(50% - 70px);

    @media screen and (min-width: $mediaWidthXLL) {
      width: 158px;
      height: 158px;
      left: calc(50% - 79px);
      top: calc(50% - 79px);
    }
    @media screen and (min-width: $mediaWidthMax) {
      width: 175px;
      height: 175px;
      left: calc(50% - 87px);
      top: calc(50% - 87px);
    }

    img, .default-image {
      width: 140px;
      height: 140px;
      border-radius: 50%;

      @media screen and (min-width: $mediaWidthXLL) {
        width: 158px;
        height: 158px;
      }
      @media screen and (min-width: $mediaWidthMax) {
        width: 175px;
        height: 175px;
      }
    }

    .default-image {
      .react-custom-icon-big, .react-custom-icon-big svg {
        width: 140px;
        height: 140px;

        @media screen and (min-width: $mediaWidthXLL) {
          width: 158px;
          height: 158px;
        }
        @media screen and (min-width: $mediaWidthMax) {
          width: 175px;
          height: 175px;
        }
      }
    }

    &.voice-active {
      img, .default-image {
        box-shadow: 0 0 30px 0 rgba(255, 255, 255, 0.90);
      }
    }
  }
}
