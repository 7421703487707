@import "../../../../styles/variables";

.conversation-chat-inside {
  display: inline-flex;
  flex-direction: column;
  flex: 1 0 auto;

  &--header {
    display: flex;
    align-items: center;
    padding: 24px;

    border-bottom: 1px solid $primary-05;

    .header-item {
      &--title {
        @include font_T1;
        flex: 1 0 auto;
      }

      &--close {
        display: inline-flex;

        .react-custom-icon {
          display: inline-flex;
          cursor: pointer;
          color: $primary-100 !important;
        }
      }
    }
  }

  &--body {
    flex: 1 0 auto;
    padding: 24px;

    .chat-list {
      max-height: calc(var(--app-height) - var(--overHeight) - 300px);

      @media (max-width: $mediaWidthMD) {
        max-height: calc(var(--app-height) - var(--overHeight) - 330px) !important;
      }
    }
  }

  &--send {
    padding: 0 24px;
  }
}
