@import "src/styles/variables";

.chat-list {
  display: flex;
  flex-direction: column-reverse;
  max-height: calc(var(--app-height) - var(--overHeight) - 396px);
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 5px;

  @media (max-width: $mediaWidthMD) {
    max-height: calc(var(--app-height) - var(--overHeight) - 430px) !important;
  }
}

