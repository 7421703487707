@import "src/styles/variables";

.add-payment {
  height: 100%;
  display: flex;
  flex-direction: column;

  &--header {
    .header-title {
      @include font_T3_bold;
      margin-bottom: 16px;
    }

    .header-subtitle {
      @include font_P2_reg($primary-90);
      margin-bottom: 16px;
    }
  }

  &--error {
    display: flex;
    justify-content: flex-start;
    margin-top: 30px;
  }

  &--actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 6px;
  }

  &--footer {
    display: flex;
    margin-top: 8px;
  }
}
