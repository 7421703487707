@import "src/styles/variables";

.welcome {
  height: 100%;

  @media (max-width: $mediaWidthMD) {
    background-color: $primary-02;
  }

  &--desktop {
    height: 100%;
    box-sizing: border-box;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;

    .desktop--header {
      .header-title {
        @include font_Sum2;
        margin-bottom: 8px;
      }

      .header-subtitle {
        @include font_sh_1($primary-80);
        color: $primary-80;
      }
    }

    .desktop--img {
      flex: 1 0 auto;
      margin-top: 24px;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      img {
        display: inline-flex;
        width: 100%;
        max-width: 603px;
      }
    }
  }

  &--mobile {
    height: 100%;
    display: none;
    flex-direction: column;

    .mobile-body {
      height: 100%;

      .expert-list--body {
        max-height: calc(var(--app-height) - 76px - 76px - 64px);
        overflow-y: auto;
      }
    }

    .mobile-footer-menu {

    }
  }

  @media (max-width: $mediaWidthMD) {
    &--desktop {
      display: none;
    }

    &--mobile {
      display: flex;
    }
  }
}
