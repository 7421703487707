@import '~@ppmcore/seven-ppm-core-shared-components-react/dist/assets/styles/variables';

@mixin loaderStyles {
  &--loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: var(--app-height);
    z-index: 10;
    transition: all 0.3s ease;

    &-show {
      opacity: 1;
      background-color: rgba(#000d24, 0.02);
    }

    &-hide {
      opacity: 0;
      background-color: transparent;
      z-index: -1;
    }
  }
}

@mixin lineClamp($clamp: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $clamp;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
