@import '../../styles/variables';

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary-100;

  @media screen and (max-width: $mediaWidthLG) {
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  @media screen and (max-width: $mediaWidthXS) {
    flex-direction: column;
    height: 120px;
  }

  span.footer--copyright {
    @include font_P4_reg($white);
  }

  span, .dialog-link {
    display: inline-block;
    margin: 10px 12px;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: $white;
    text-decoration: none;
  }

  .dialog-link {
    @include font_P4_reg($white);

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
