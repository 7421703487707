@import '../../../styles/variables';

.chat-outgoing {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $call-bg;
  height: 100vh;
  width: 100%;

  .chat-outgoing-inside {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 394px;

    .photo {
      margin-bottom: 24px;

      img, .default-image {
        width: 140px;
        height: 140px;
        border-radius: 50%;

        @media screen and (min-width: $mediaWidthXLL) {
          width: 158px;
          height: 158px;
        }
        @media screen and (min-width: $mediaWidthMax) {
          width: 175px;
          height: 175px;
        }
      }
    }

    .fio {
      @include font_H1($white);
    }

    .calling-text {
      margin-top: 8px;
      margin-bottom: 24px;
      @include font_P1_reg($white);

      .dot {
        width: 20px;
        display: inline-block;
        aspect-ratio: 7;
        background: radial-gradient(circle closest-side, $white 90%, $primary-100) 0/calc(100%/3) 100% space;
        clip-path: inset(0 100% 0 0);
        animation: d1 1.25s steps(4) infinite;
      }
      @keyframes d1 {to{clip-path: inset(0 -34% 0 0)}}
    }

    .cancel-call {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: $red-100;

      &:hover {
        cursor: pointer;
        opacity: 0.75;
      }
    }
  }
}

