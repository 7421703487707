@import "src/styles/variables";

.sidebar-header {
  position: relative;
  padding: 16px 24px 24px 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .header-item {
    display: inline-flex;
    align-self: center;

    &:first-child {
      flex: 1 0 auto;
    }

    &:last-child {
      display: flex;
      justify-content: flex-end;

      .react-custom-icon {
        cursor: pointer;
        margin-left: 16px;
      }
    }

    &.hide-info {

      .ant-dropdown-trigger {
        flex-direction: column;

        .react-custom-icon {
          margin-top: 8px;
        }
      }

      .img-logo {
        width: 40px;
        margin-top: 8px;
      }
    }

    &.hide-info + .header-item .react-custom-icon {
      margin-left: 0;
    }
  }
}

.ant-dropdown-menu-title-content {
  display: flex;
  align-items: center;

  .img-info {
    flex: 1 0 auto;
  }

  @media (max-width: $mediaWidthMD) {
    .react-custom-icon {
      margin-left: 10px;
    }
  }
}
