@import "src/styles/variables";

.business-information {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  box-sizing: border-box;
  height: 100%;

  //&.full-height-page {
  //  height: 100%;
  //}

  &--empty {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;

    .empty-img {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      img {
        display: inline-flex;
        width: 100%;
        max-width: 460px;
      }
    }

    .empty-subtitle {
      margin-top: 24px;
      @include font_sh_1($primary-80);
    }
  }

  &-title {
    @include font_H1;
    margin-bottom: 16px;
  }

  &-subtitle {
    @include font_P1_med($primary-80);
    margin-bottom: 24px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    .content-item {

      &-title {
        margin-bottom: 16px;
        @include font_T3_bold;
      }

      &-value {
        @include font_P1_reg;

        &-row {
          display: flex;
          gap: 24px;
          margin-top: 16px;

          &:first-child, &:empty {
            margin-top: 0;
          }

          @media screen and (max-width: $mediaWidthLG) {
            flex-direction: column;
          }
        }
      }
    }
  }

  .spin-element.full-height {
    height: calc(var(--app-height) - 90px);

    @media screen and (max-width: $mediaWidthMD) {
      height: calc(var(--app-height) - 240px);
    }
  }
}
