@import '../../../../styles/variables';

.chat-header {
  position: relative;
  width: 100%;
  background-color: $call-bg;

  .chat-header-item {
    min-width: 200px;

    @media screen and (max-width: $mediaWidthMD) {
      min-width: auto;
    }

    &:last-child {
      display: flex;
      align-items: center;
      column-gap: 24px;
      justify-content: flex-end;

      //@media screen and (max-width: $mediaWidthXS) {
      //  column-gap: 8px;
      //}
    }
  }

  &-inside {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px 24px;
    column-gap: 40px;
    height: 62px;

    @media screen and (max-width: $mediaWidthMD) {
      flex-direction: column;
      height: 100px;
      gap: 8px;
    }

    .timer-section, .free-minutes-countdown {
      display: flex;
      align-items: center;
      min-width: max-content;
      max-width: 281px;
      height: 40px;
      background: $primary-80;
      border-radius: 12px;
      padding: 0 24px;

      @media screen and (max-width: $mediaWidthMD) {
        margin-bottom: 2px;
        padding: 0 16px;
      }

      @media screen and (min-width: $mediaWidthMax) {
        max-width: 310px;
      }

      @media screen and (max-width: $mediaWidthXS) {
        max-width: 225px;
      }

      .react-custom-icon {
        margin-right: 7px;
      }

      .connecting-process {
        display: flex;
        align-items: center;

        span {
          width: 1px;
          height: 22px;
          margin: 0 20px;
          background-color: $primary-60;
        }

        &-text {
          @include font_T2($white);
        }
      }
    }

    .free-minutes-countdown {
      @include font_T2($white);
      padding: 0;
    }

    .call-type {
      @include font_T1($white);
      width: 100%;

      @media screen and (max-width: $mediaWidthMD) {
        width: 100%;
        justify-content: center;
        @include font_T3_bold($white);
      }

      &-inside {
        display: flex;
        align-items: center;
        width: 100%;

        @media screen and (max-width: $mediaWidthMD) {
          justify-content: center;
        }

        .call-type-inside--name {
          @include lineClamp(1);
          white-space: break-spaces;
          word-break: break-all;
          max-width: 80%;

          @media screen and (max-width: $mediaWidthMD) {
            @include lineClamp(2);
            text-align: center;
            word-break: break-word;
          }
        }

        .react-custom-icon {
          margin-left: 8px;
          padding: 4px;
          background-color: $primary-90;
          border-radius: 50px;
        }
      }
    }

    .chat-end {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: $red-100;

      &:hover {
        cursor: pointer;
        opacity: 0.75;
      }
    }
  }
}
