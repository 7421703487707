@import '../../../../styles/variables';

.chat-footer {
  position: relative;
  width: 100%;
  bottom: 0;
  background-color: $call-bg;
  z-index: 4;

  &-inside {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    margin: 0 auto;
    width: 156px;

    .react-custom-icon {
      svg {
        fill: $white;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.75;
      }
    }

    &.inside-audio {
      width: 88px;
    }
  }
}
