@import '../../../styles/variables';

.chat-in-progress {
  position: relative;
  background-color: $call-bg;

  .spin-element {
    height: 100vh;
    background-color: $call-bg;
    align-items: center;
    margin: 0;
  }
}
