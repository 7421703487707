@import '../../../styles/variables';

.chat-checkout {
  box-sizing: border-box;
  padding: 24px;

  .wallet-button-dark {
    .ant-btn.dark.icon-left {
      .react-custom-icon.apple-pay-icon {
        color: $primary-100 !important;

        svg path {
          fill: $primary-100 !important;
        }
      }
    }
  }

  .back-icon {
    position: absolute;
    left: 0;

    svg {
      color: $white;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.75;
    }
  }

  .title {
    position: relative;
    margin: 0 auto 32px;
    max-width: 1022px;
    text-align: center;
    padding-top: 16px;
    @include font_H1($white);
  }

  .chat-checkout-inside {
    display: flex;
    margin: 0 auto;
    max-width: 1024px;
    min-height: 500px;

    .body-item {
      width: 50%;
      display: flex;

      .consultation-info {
        .stripe-footer {
          justify-content: flex-start !important;
        }
      }

      &--divider {
        border-right: 1px solid $primary-60;
        padding-right: 40px;
        margin-right: 40px;
        box-sizing: border-box;
      }

      &:last-child {
        width: calc(50% - 41px);
      }
    }
  }

  @media (max-width: $mediaWidthMD) {
    .chat-checkout-inside {
      flex-direction: column;

      .body-item {
        width: 100%;
        display: block;

        .consultation-info {
          width: 100%;
        }

        .ant-form {
          max-width: unset;
        }

        &--divider {
          border-right: none;
          border-bottom: 1px solid $primary-60;
          padding-right: 0;
          padding-bottom: 40px;
          margin-right: 0;
          margin-bottom: 40px;
          box-sizing: border-box;
        }

        &:last-child {
          width: auto;
        }
      }
    }
  }
}
