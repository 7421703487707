@import '../../../../styles/variables';

.publisher-video {
  position: absolute;
  width: 194px;
  height: 109px;
  bottom: 24px;
  right: 24px;
  z-index: 8;
  background: $primary-100 !important;

  @media screen and (min-width: $mediaWidthXLL) {
    width: 218px;
    height: 123px;
  }
  @media screen and (min-width: $mediaWidthMax) {
    width: 242px;
    height: 136px;
  }

  .publisher-fio {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 16px;
    left: 8px;
    z-index: 5;
    @include font_P3_semibold($white);

    svg {
      background-color: $primary-90;
      padding: 4px;
      border-radius: 50px;
    }
  }

  .publisher-inside {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .publisher-section {
    position: relative;
    z-index: 2;
  }

  .publisher-image {
    position: absolute;
    width: 64px;
    height: 64px;
    left: calc(50% - 32px);
    top: calc(50% - 32px);

    @media screen and (min-width: $mediaWidthXLL) {
      width: 72px;
      height: 72px;
      left: calc(50% - 36px);
      top: calc(50% - 36px);
    }
    @media screen and (min-width: $mediaWidthMax) {
      width: 80px;
      height: 80px;
      left: calc(50% - 40px);
      top: calc(50% - 40px);
    }

    img, .react-custom-icon-big svg {
      width: 64px;
      height: 64px;
      border-radius: 50%;

      @media screen and (min-width: $mediaWidthXLL) {
        width: 72px;
        height: 72px;
      }
      @media screen and (min-width: $mediaWidthMax) {
        width: 80px;
        height: 80px;
      }
    }

    &.voice-active {
      img, .react-custom-icon-big svg {
        box-shadow: 0 0 30px 0 rgba(255, 255, 255, 0.90);
      }
    }
  }

  .OT_publisher {
    border-radius: 1px;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    min-width: 194px;
    min-height: 109px;

    @media screen and (min-width: $mediaWidthXLL) {
      min-width: 218px;
      min-height: 123px;
    }
    @media screen and (min-width: $mediaWidthMax) {
      min-width: 242px;
      min-height: 136px;
    }
  }

  .OT_widget-container {
    background: transparent !important;
  }

  .OT_archiving,
  .OT_mute,
  .OT_audio-level-meter,
  .OT_video-poster{
    display: none !important;
  }

  .OT_publisher .OT_edge-bar-item.OT_mode-auto,
  .OT_publisher:hover .OT_edge-bar-item.OT_mode-auto {
    opacity: 0 !important;
  }

  &.publisher-mobile-mode {
    width: 109px;
    height: 194px;

    .OT_publisher {
      min-width: 109px;
      min-height: 194px;
    }
  }
}

.publisher-audio {
  .OT_archiving,
  .OT_mute,
  .OT_audio-level-meter,
  .OT_widget-container,
  .OT_video-poster {
    display: none !important;
  }

  .OT_publisher {
    min-width: 0;
    min-height: 0;
  }

  .publisher-fio {
    min-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include font_P3_semibold($white);

    @media screen and (min-width: $mediaWidthXLL) {
      min-height: 30px;
    }

    svg {
      margin-left: 8px;
    }
  }

  .publisher-image {
    padding: 12px;
    width: 140px;
    height: 140px;
    left: calc(50% - 70px);
    top: calc(50% - 70px);

    @media screen and (min-width: $mediaWidthXLL) {
      width: 158px;
      height: 158px;
      left: calc(50% - 79px);
      top: calc(50% - 79px);
    }
    @media screen and (min-width: $mediaWidthMax) {
      width: 175px;
      height: 175px;
      left: calc(50% - 87px);
      top: calc(50% - 87px);
    }

    img, .react-custom-icon-big svg {
      width: 140px;
      height: 140px;
      border-radius: 50%;

      @media screen and (min-width: $mediaWidthXLL) {
        width: 158px;
        height: 158px;
      }
      @media screen and (min-width: $mediaWidthMax) {
        width: 175px;
        height: 175px;
      }
    }

    &.voice-active {
      img, .react-custom-icon-big svg {
        box-shadow: 0 0 30px 0 rgba(255, 255, 255, 0.90);
      }
    }
  }
}
