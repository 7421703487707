@import '../../../../styles/variables';

.chat-content {
  height: calc(var(--app-height) - 159px);
  position: relative;
  display: flex;

  @media screen and (min-width: $mediaWidthXLL) {
    height: calc(var(--app-height) - 162px);
  }

  @media screen and (min-width: $mediaWidthMax) {
    height: calc(var(--app-height) - 166px);
  }

  @media screen and (max-width: $mediaWidthMD) {
    height: calc(var(--app-height) - 192px);
  }

  &--main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    position: relative;
    gap: 56px;
    max-width: 314px;

    .default-image {
      height: 140px;
      width: 140px;
      border-radius: 50%;

      @media screen and (min-width: $mediaWidthXLL) {
        width: 158px;
        height: 158px;
      }
      @media screen and (min-width: $mediaWidthMax) {
        width: 175px;
        height: 175px;
      }
    }

    .user-text {
      @include font_H1($white);
      text-align: center;
      margin-top: 16px;
    }

    @media (max-width: $mediaWidthMD) {
     display: none;
    }
  }

  &--chat {
    display: inline-flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: calc(var(--app-height) - 159px);
    overflow: hidden;
    background-color: $white;
    transition: all 0.3s ease;

    @media screen and (max-width: $mediaWidthMD) {
      height: calc(var(--app-height) - 192px);
      position: absolute;
      max-width: 100%;
      min-width: 100%;
      right: 0;
      z-index: 15;

      .chat-list {
        max-height: calc(var(--app-height) - 432px);
      }
    }

    &.close-sidebar {
      max-width: 0;
      min-width: 0;
    }
  }

  .bad-connection-spinner {
    position: absolute;
    z-index: 9;
    width: 100%;
    height: calc(var(--app-height) - 150px);
    background: rgba(0, 13, 36, 0.5);

    @media screen and (max-width: $mediaWidthMD) {
      height: calc(var(--app-height) - 192px);
    }

    .spin-element {
      height: calc(var(--app-height) - 159px);

      @media screen and (max-width: $mediaWidthMD) {
        height: calc(var(--app-height) - 192px);
      }
    }
  }
}
