@import '../../styles/variables';

.browser-not-supported {
  &-inside {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(var(--app-height) - 34px);

    @media screen and (min-width: $mediaWidthXLL) {
      height: calc(var(--app-height) - 37px);
    }

    @media screen and (min-width: $mediaWidthMax) {
      height: calc(var(--app-height) - 40px);
    }

    @media screen and (max-width: $mediaWidthLG) {
      padding-bottom: 34px;
      height: calc(var(--app-height) - 34px);
    }
    @media screen and (max-width: $mediaWidthXS) {
      padding-bottom: 120px;
      height: calc(var(--app-height) - 120px);
    }

    .logo-section {
      width: 100%;
      text-align: center;
      margin-bottom: 12px;

      img {
        @media screen and (min-width: $mediaWidthXLL) {
          width: 164px;
        }
        @media screen and (min-width: $mediaWidthMax) {
          width: 182px;
        }
      }
    }

    .page-subtitle {
      margin-bottom: 24px;
      @include font_P1_reg();
    }

    .browsers-items {
      display: flex;
      justify-content: space-between;

      .browsers-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        width: 105px;

        .item-name {
          margin-top: 8px;
          margin-bottom: 2px;
          text-align: center;
          @include font_P2_med();
        }

        .item-version {
          text-align: center;
          @include font_P3_reg();
        }
      }

      &.two-items {
        margin: 24px auto 0;
        max-width: 234px;
      }
    }
  }

  .footer {
    @media screen and (max-width: $mediaWidthXS) {
      height: auto;
    }
  }
}
