.success-checkout-modal {
  .ant-modal-header {
    margin-bottom: 8px !important;
  }

  .ant-modal-title {
    text-align: center;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
  }
}
