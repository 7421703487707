@import 'src/styles/variables';

.expert-profile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8.5px 24px;
  box-sizing: border-box;
  border-bottom: 1px solid $primary-05;
  gap: 10px;

  &--item {
    display: flex;
    align-items: center;

    &:has(.expert-profile-menu:empty) {
      display: none;
    }

    &.item-card {
      min-width: calc(40% - 5px);
      flex: 1 1 auto;
    }

    .item-card {

      &--avatar {
        margin-right: 16px;
        display: inline-flex;
      }

      &--info {
        width: 100%;
        display: flex;
        flex-direction: column;

        .info-name {
          display: inline-flex;
          @include font_H1;

          &--text {
            max-width: 80%;

            .ant-tooltip--wrapper {
              @include lineClamp(1);
              width: 100%;
              word-break: break-all;
              white-space: break-spaces;
            }
          }

          &--mark {
            display: inline-flex;
            align-items: center;
            margin-left: 16px;

            .react-custom-icon {
              color: $primary-100 !important;
            }

            .ant-spin-spinning.loader-item {
              width: 25px;
              height: 25px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .react-custom-icon:not(.rolling-icon) {
              cursor: pointer;
              display: inline-flex;
            }
          }
        }

        .info-speciality {
          @include font_P3_reg($primary-80);
          display: inline-flex;
          align-items: center;
        }
      }
    }

    &.item-menu {
      //min-width: calc(60% - 5px);
      //flex: 1 0 auto;
      width: 100%;

      @media (max-width: $mediaWidthMD) {
        //flex: auto;
        width: auto;
      }
    }

    &.item-notifier {
      flex: 1 0 auto;
      display: flex;
      justify-content: flex-end;
    }
  }

  //@media (max-width: $mediaWidthXL) {
  @media (max-width: 1279px) {
    flex-wrap: wrap;
  }

  @media (max-width: $mediaWidthLG) {
    .item-card {

      &--mark {
        margin-left: 8px;
      }
    }
  }

  @media (max-width: $mediaWidthMD) {
    .item-card {
      display: none;
    }
  }
}
