@import '../../styles/variables';

.temp-user-sign-up-modal {
  .ant-modal-content {
    padding: 24px 24px 8px 24px;
  }

  .ant-modal {
    .ant-modal-close {
      display: none;
    }
  }

  .temp-user-terms {
    padding: 0 8px;
    margin-bottom: 40px;
    text-align: center;
    @include font_P4_reg();

    a {
      color: $blue-100;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .info-section {
    padding: 8px;
    border-radius: 12px;
    background-color: $blue-10;
    display: flex;
    column-gap: 8px;

    .react-custom-icon {
      color: $blue-100 !important;
    }

    &-text {
      @include font_P3_reg();
    }
  }
}
