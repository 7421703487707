@import '../../styles/variables';

.canceling-appointment-modal {
  .ant-modal-header {
    margin-bottom: 24px !important;
  }

  .ant-modal-footer {
    margin-top: 43px !important;
  }

  .ant-modal-close {
    display: none;
  }

  .canceling-appointment-modal--body.modal-body {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .modal-body--item {
      &.item-notifier {
        .info-message {
          background: $orange-10 !important;
          border: 1px solid $orange-100 !important;
          box-sizing: border-box;

          .react-custom-icon {
            color: $orange-100 !important;
          }
        }
      }

      .ant-input-affix-wrapper {
        border: none !important;
        box-shadow: none !important;
        outline: none !important;

        textarea {
          @include font_F1;
          border: 1px solid $primary-20 !important;
          border-radius: 12px !important;
          resize: none !important;
          padding: 15px 16px;

          &::placeholder {
            color: $primary-60 !important;
          }

          &::-webkit-scrollbar {
            width: 4px;
            border-radius: 2px;
            background-color: transparent;
          }

          &::-webkit-scrollbar-track {
            margin: 10px 0;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $primary-80;
            width: 4px;
            border: 1px solid $primary-80;
            border-radius: 2px;
          }
        }

        &-focused {
          border: none !important;
          box-shadow: none !important;
          outline: none !important;

          textarea {
            border: 1px solid $primary-100 !important;
          }
        }
      }
    }
  }
}
