@import 'src/styles/variables';

.ant-drawer.expert-filter-modal {
  .ant-drawer-content-wrapper {
    width: 100vw !important;
    max-width: 604px;

    .ant-drawer-content .ant-drawer-wrapper-body, .ant-drawer-content {
      .ant-drawer-header {
        border-bottom: none;

        &-title {
          .ant-drawer-close {
            order: 1;
            margin-right: 0;
            margin-left: 12px;

            .react-custom-icon {
              color: $primary-100 !important;
            }
          }

          .ant-drawer-title {
            @include font_T1;
          }
        }
      }

      .ant-drawer-body {
        .drawer-body {
          height: 100%;
          display: flex;
          flex-direction: column;

          &--item {

            &.flex-items {
              display: flex;
              align-items: flex-end;
              flex-wrap: wrap;
              gap: 8px;

              .flex-items-label {
                @include font_T3_bold;
                width: 100%;
              }

              .ant-form-item {
                width: calc(50% - 4px);

                .ant-input-number {
                  width: 100%;
                }
              }
            }

            .dropdown-element {
              margin: 0;
            }

            &.actions-items {
              flex: 1 0 auto;
              display: flex;
              align-items: flex-end;
              justify-content: space-between;

              .ant-btn {
                margin-right: 10px;
              }

              .ant-btn:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }

      .ant-drawer-footer {
        display: flex;
        justify-content: space-between;
        padding: 24px;
        border-top: none !important;

        .ant-btn:first-child {
          margin-right: 8px;
        }
      }
    }
  }
}
