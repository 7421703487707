@import "src/styles/variables";

.back-action {
  display: flex;
  align-items: center;

  &--arrow {
    display: inline-flex;
    cursor: pointer;
    margin-right: 5px;

    .react-custom-icon {
      color: $primary-100 !important;
    }
  }

  &--info {
    display: flex;
    flex-direction: column;

    .info-title {
      @include font_H1_mobile;

      .ant-tooltip--wrapper {
        @include lineClamp(1);
        width: 100%;
        word-break: break-all;
        white-space: break-spaces;
      }
    }

    .info-subtitle {
      @include font_P3_reg($primary-80);
      line-height: 15px !important;
    }
  }

  &--mark {
    display: inline-flex;
    align-self: flex-start;
    cursor: pointer;
    margin-left: 16px;

    .react-custom-icon {
      color: $primary-100 !important;
    }

    .ant-spin-spinning.loader-item {
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
