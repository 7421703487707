@import "src/styles/variables";

.card-item {
  position: relative;
  padding: 16px 41px 16px 16px;
  display: flex;
  align-items: center;
  background-color: $primary-05;
  border-radius: 1px;
  box-sizing: border-box;

  &--info {
    width: 100%;

    .info-item {
      display: flex;
      align-items: center;

      &-logo {
        margin-right: 16px;

        img, svg {
          width: 36px;
          height: 24px;
        }
      }

      &-number {
        @include font_T2;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 4px;
        margin-bottom: 4px;
        width: 100%;
      }

      &-date {
        @include font_P4_reg($primary-80);
      }

      .default-mark {
        @include font_P3_reg($white);
        border-radius: 32px;
        background-color: $primary-100;
        display: inline-flex;
        align-self: flex-start;
        padding: 2px 8px;
      }
    }
  }

  &--actions {
    position: absolute;
    right: 16px;
    top: 16px;
    display: inline-flex;
    align-self: flex-start;

    .react-custom-icon {
      color: $primary-100 !important;
    }

    .ant-spin-spinning.loader-item {
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ant-dropdown-trigger {
      display: inline-flex;
      cursor: pointer;

      div {
        display: inline-flex;
      }
    }
  }
}

.card-actions {

  .actions-item {
    display: flex;
    align-items: center;
    @include font_P2_reg;

    .react-custom-icon {
      margin-right: 12px;
      color: $primary-100 !important;
    }
  }

  .ant-dropdown-menu-item-disabled {
    .actions-item {
      pointer-events: none;

      .react-custom-icon {
        opacity: 0.3;
      }
    }
  }
}
