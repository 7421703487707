@import 'src/styles/variables';

.wallet-button {
  display: flex;
  align-items: center;

  &--item {
    @include font_P2_reg($primary-60);
    width: 100%;
    margin-right: 16px;

    .ant-btn {
      width: 100%;
      height: 44px;
      display: flex;
      justify-content: center;
    }

    .spin-element {
      margin: 0 auto;
      height: 44px;
    }
  }

  &--item:last-child {
    margin-right: 0;
  }
}
