@import 'src/styles/variables';

.appointment-notification {
  display: flex;
  align-items: center;
  justify-content: center;

  &.show-active {
    .call-notification--body {
      background-color: $orange-05 !important;
    }
  }

  &--body {
    display: flex;
    align-items: center;
    margin-right: 8px;
    background-color: $primary-05;
    border-radius: 32px;
    padding: 8px 16px;
    transition: all 0.3s ease;

    .body-item {
      margin-top: 0;

      &--time {
        @include font_P3_reg($primary-80);
        white-space: nowrap;
        margin-right: 16px;
      }

      &--icon {
        display: inline-flex;
        margin-right: 4px;

        .react-custom-icon {
          color: $orange-100 !important;
        }
      }

      &--message {
        @include font_P3_semibold;
        text-align: center;
      }
    }

    @media (max-width: $mediaWidthXS) {
      flex-wrap: wrap;
      justify-content: center;

      .body-item--message {
        width: 100%;
      }
    }
  }
}
