@import '../../../styles/variables';

.next-payment-countdown {
  //@include font_T2($white);
  display: inline-flex;
  align-items: center;

  &--text {
    @include font_T2;

    span {
      color: $red-100;
      margin-right: 2px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.red-time {
    .next-payment-countdown--text {
      color: $red-100;
    }
  }
}
