@import "src/styles/variables";

.payment-methods {
  height: 100%;
  display: flex;
  flex-direction: column;

  &--header {
    .header-title {
      @include font_T3_bold;
      margin-bottom: 16px;
    }

    .header-subtitle {
      @include font_P2_reg_italic($primary-60);
      font-style: italic;
      margin-bottom: 16px;
    }
  }

  &--loading {
    display: flex;
    justify-content: center;
  }

  &--cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    .card-item {
      width: calc(50% - 8px);
      flex: 1 0 auto;
    }

    @media (max-width: $mediaWidthMD) {
      flex-direction: column;
      flex-wrap: nowrap;

      .card-item {
        width: 100%;
        flex: auto;
      }
    }
  }

  &--footer {
    display: none;
    flex: 1 0 auto;
    margin-top: 56px;

    @media (max-width: 1000px) {
      display: flex;
    }
  }
}
