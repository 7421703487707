@import '../../../../styles/variables';

.form-bottom-actions {
  .forgot-password-link {
    display: inline-block;
    margin-bottom: 24px;
  }

  a {
    @include font_P1_reg($blue-100);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .info-text {
    @include font_P1_reg($primary-80);
    margin-bottom: 24px;
  }
}
