@import "src/styles/variables";

.aside-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .ant-tooltip--wrapper {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--item, .actions-item {
    @include font_P2_reg($primary-100);
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 0;

    .icon-item {
      display: inline-flex;
      margin-right: 8px;

      .react-custom-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $primary-100 !important;
      }
    }

    &.active-item {
      font-weight: 700 !important;
      color: $blue-100 !important;

      .react-custom-icon {
        color: $blue-100 !important;
      }
    }

    &.disabled-btn {
      background: transparent;
      pointer-events: none;

      &.active-item, &:hover {
        background: transparent;
      }

      .react-custom-icon {
        color: $primary-20 !important;
      }
    }
  }

  &--actions {
    flex: 1 0 auto;
    display: none;
    justify-content: flex-end;

    .react-custom-icon {
      cursor: pointer;
      transform: rotate(90deg);
    }
  }

  @media (max-width: $mediaWidthLG) {
    & {
      margin-bottom: 24px;
      flex-direction: row !important;
      align-items: center;
      gap: 24px;

      .ant-tooltip--wrapper {
        margin-bottom: 0;
      }

      &--item {
        margin-bottom: 0 !important;
        margin-right: 0;
        padding: 0 !important;

        .icon-item {
          margin-right: 0;
        }

        .text-item {
          display: none;
        }
      }

      &--actions {
        display: flex;
        align-self: center;
      }
    }
  }
}

.ant-dropdown-menu-item {
  .text-item {
    display: inline-flex !important;
  }
}
