@import "src/styles/variables";

.menu-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
  padding-bottom: 24px;

  @media (max-width: $mediaWidthMD) {
    align-items: flex-start;
    justify-content: flex-start;
  }

  .section-item {
    display: flex;
    flex-direction: column;

    .ant-tooltip--wrapper {
      margin-bottom: 16px;
    }

    @media (max-width: $mediaWidthMD) {
      height: auto;
    }

    &.top-section {
      padding-bottom: 24px;

      .ant-tooltip--wrapper:last-child {
        margin-bottom: 0;
      }
    }

    &.center-section {
      justify-content: center;
      padding-bottom: 24px;

      .ant-tooltip--wrapper:last-child {
        margin-bottom: 0;
      }
    }

    &.bottom-section {
      justify-content: flex-end;

      .ant-tooltip--wrapper:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.menu-list-not-divided {
    //justify-content: flex-start;
    //height: auto;
    //
    //.section-item {
    //  height: auto;
    //}
  }
}
