@import '../../../../styles/variables';

.time-left-message {
  position: absolute;
  width: 100%;
  z-index: 12;
  top: 0;

  &-inside {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin: 24px auto 0;
    width: max-content;
    padding: 4px 12px;
    background: $red-10;
    border-radius: 30px;
    @include font_P1_med($primary-100);

    .react-custom-icon {
      &:last-child:hover {
        cursor: pointer;
        opacity: 0.75;
      }
    }

    &.included-bad-connection {
      margin: 76px auto 0;

      @media screen and (max-width: $mediaWidthMD) {
        margin: 98px auto 0;
      }
    }
  }
}
