@import 'src/styles/variables';

.ant-radio-group.cards-radio {
  display: flex;
  flex-direction: column;
  font-size: initial;

  .radio-group-item {
    display: flex;
    align-items: center;
    padding: 16px;
    background-color: $primary-05;
    margin-bottom: 16px;
    border-radius: 1px;

    &:last-child {
      margin-bottom: 0;
    }

    &-logo {
      width: 60px;
      margin-right: 16px;
    }

    &-info {
      flex: 1 0 auto;

      .item-info-title {
        @include font_T2;
        margin-bottom: 4px;
        display: flex;
        align-items: center;

        .default-mark {
          margin-left: 20px;
          background-color: $primary-100;
          border-radius: 32px;
          padding: 3.5px 8px;
          @include font_P3_reg($white);
        }
      }

      .item-info-expiration-date {
        @include font_P4_reg;
      }
    }

    &-actions {
      display: flex;
      align-items: center;

      .ant-radio-wrapper {
        margin-bottom: 0 !important;

        .ant-radio.ant-radio-checked {
          .ant-radio-inner {
            background-color: transparent;
            border-color: $blue-100;
          }
          .ant-radio-inner::after {
            background-color: $blue-100;
          }
        }
      }

      .react-custom-icon {
        cursor: pointer;
        color: $blue-100 !important;
      }
    }
  }
}
