@import 'src/styles/variables';

.message-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 5px;

  &.reverse-chat {
    flex-direction: column-reverse;

    .message-list--item {
      margin-top: 16px;
      margin-bottom: 0;

      &:last-child {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}
