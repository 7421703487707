@import '../../../styles/variables';

.tu-first-step {
  .link-to-sign-in {
    margin-top: 44px;
    margin-left: 16px;
    @include font_B1();

    a {
      color: $blue-100;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .tu-footer {
    display: flex;
    justify-content: space-between;
  }
}
