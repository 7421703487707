@import 'src/styles/variables';

.expert-item {
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  display: inline-flex;
  align-items: flex-start;

  &-avatar {
    position: relative;
    display: inline-flex;
    margin-right: 10px;

    .avatar-badge {
      position: absolute;
      right: 0;
      bottom: 0;
      display: inline-flex;

      &.avatar-badge-worker_online {
        .react-custom-icon {
          color: $green-100 !important;
        }
      }

      &.avatar-badge-worker_offline, &.avatar-badge-deactivated {
        .react-custom-icon {
          color: $primary-60 !important;
        }
      }

      &.avatar-badge-worker_busy {
        .react-custom-icon {
          color: $yellow-100 !important;
        }
      }
    }
  }

  &-info {
    display: inline-flex;
    justify-content: center;
    align-self: center;
    flex-direction: column;
    width: 100%;

    .info-title {
      @include font_T3_bold;
      margin-bottom: 1px;

      .ant-tooltip--wrapper {
        @include lineClamp(1);
        word-break: break-all;
      }
    }

    .info-name {
      @include font_P3_reg;
      display: inline-flex;
      align-items: center;
      padding-right: 30px;

      &:empty {
        display: none;
      }

      //.speciality-name {
      //  @include lineClamp(1);
      //  max-width: 160px;
      //}
      //
      //.speciality-counter {
      //  display: inline-flex;
      //  align-items: center;
      //  cursor: help;
      //  white-space: nowrap;
      //
      //  span::before {
      //    content: ', +';
      //  }
      //
      //  span:empty {
      //    display: none;
      //  }
      //}
    }

    .info-subtitle {
      margin-top: 4px;
      display: inline-flex;
      align-items: center;

      &--has-messages {
        margin-left: 8px;
      }
    }

    .info-prices {
      margin-top: 8px;

      &:empty {
        margin-top: 0;
      }

      .prices-item {
        @include font_P4_reg($primary-60);
        display: inline-flex;
        align-items: center;
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }

        .react-custom-icon {
          display: inline-flex;
          color: $blue-100 !important;
          margin-right: 4px;
        }

        span.bold-text {
          @include font_P3_semibold;
          font-weight: 700 !important;
          margin-right: 2px;
        }
      }
    }
  }
}
