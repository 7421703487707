@import "src/styles/variables";

.sidebar-mobile {
  position: relative;
  display: flex;
  padding: 17px 24px;
  box-sizing: border-box;
  gap: 16px;
  min-height: 76px;
  max-height: 76px;
  background-color: $primary-02;

  &--item {
    display: flex;
    align-items: center;

    &.item-info {
      width: 100%;
    }

    &.item-menu {
      align-items: center;

      &.open-menu {
        .react-custom-icon {
          transform: rotate(180deg);
        }
      }

      .react-custom-icon {
        cursor: pointer;
        transition: all 0.3s ease;
      }
    }
  }

  &--menu {
    position: absolute;
    overflow: hidden;
    z-index: 10;
    width: 100%;
    top: 100%;
    left: 0;
    height: calc(var(--app-height) - 78px);

    .right-menu {
      width: 100%;
      height: 100%;
      max-width: unset;

      &-body {
        justify-content: flex-start;

        .menu-list {
          justify-content: flex-start;

          .top-section, .center-section {
            .ant-tooltip--wrapper {
              margin-bottom: 16px !important;
            }
          }

          .center-section {
            flex: 0 0 auto;
            padding-bottom: 0;

            .ant-tooltip--wrapper:last-child {
              margin-bottom: 0 !important;
            }
          }

          .bottom-section {
            flex: 1 0 auto;
            justify-content: center;
          }
        }
      }
    }
  }

  .stay-with-us {
    width: 30px;
  }
}
