@import "src/styles/variables";

.company-added-modal {
  .modal-body-item--description {
    @include font_P1_med($primary-80);
    margin-bottom: 0;
  }

  .ant-modal-footer {
    margin-top: 24px;
    justify-content: center;
  }
}
