@import '../../../../styles/variables';

.tu-power-by {
  display: flex;
  justify-content: center;

  &--inside {
    display: inline-flex;
    justify-content: center;
    column-gap: 4px;
    margin-top: 24px;
    cursor: pointer;

    .text {
      @include font_P5_reg($primary-40);
    }

    img {
      width: 70px;

      @media screen and (min-width: $mediaWidthXLL) {
        width: 79px;
      }
      @media screen and (min-width: $mediaWidthMax) {
        width: 88px;
      }
    }
  }
}
