@import '../../styles/variables';

.widget-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;

  &.no-auth-user {

    .widget-wrapper--body.widget-body {
      [class*="-item--bottom-menu"] {
        display: none;
      }

      .welcome {
        .expert-list--body {
          max-height: calc(var(--app-height) - 80px);
        }
      }

      .counsellor-layout {
        height: calc(var(--app-height) - 76px) !important;

        .history-list {
          .history-list--body {
            height: calc(100%);
          }
        }
      }

      .call-notification-page {
        height: calc(var(--app-height));
      }

      .conversation-notifications-page {
        height: calc(var(--app-height));
      }

      .call-section {
        height: calc(var(--app-height));

        .call-finished {
          .power-by {
            display: none;
          }
        }

        .video-call {

          .video-call-inside {

            .video-content {

              .call-chat {
                height: 100% !important;

                .call-chat-inside--body {
                  height: 100%;
                  flex: auto;

                  .chat-list {
                    max-height: calc(var(--app-height) - var(--overHeight) - 400px);

                    @media (max-width: $mediaWidthMD) {
                      max-height: calc(var(--app-height) - var(--overHeight) - 300px - 150px) !important;
                    }
                  }
                }
              }
            }
          }
        }
      }

      // TODO
      .chat-section {
        height: calc(var(--app-height));

        .chat-finished {
          .power-by {
            display: none;
          }
        }

        .chat-in-progress {

          .chat-in-progress-inside {

            .chat-content {
              //height: calc(var(--app-height) - 210px);

              .chat-content--chat {

                .conversation-chat-inside--body {

                  .chat-list {

                    @media (max-width: $mediaWidthMD) {
                      max-height: calc(var(--app-height) - var(--overHeight) - 320px) !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    [class*="layout--desktop"] {

      [class*="-item--content"] {

        .content-container {
          .counsellor-layout {
            height: var(--app-height) !important;
          }
        }
      }
    }
  }

  &.auth-user {

    .widget-footer {
      @media (max-width: $mediaWidthMD) {
        display: none;
      }
    }

    .widget-wrapper--body.widget-body {

      .sidebar-filter-item.sign-in {
        display: none;
      }

      .terms-of-service, .privacy-policy, .notifications, .transactions:not(.transactions-counsellor) {
        height: calc(var(--app-height) - var(--outgoingHeight)) !important;

        @media (max-width: $mediaWidthMD) {
          height: calc(var(--app-height) - 46px - var(--outgoingHeight)) !important;
        }
      }

      .profile-settings {
        height: calc(var(--app-height) - 46px - var(--outgoingHeight)) !important;

        .profile-settings-inside {
          height: calc(var(--app-height) - 30px - 55px - 80px - 16px - var(--outgoingHeight)) !important;
        }
      }

      .welcome, .history--mobile, .favorites {

        .expert-list--header {
          padding: 16px 24px 24px 24px;
        }

        .expert-list--body {
          max-height: calc(var(--app-height) - 96px - 56px - var(--outgoingHeight));

          @media (max-width: $mediaWidthMD) {
            max-height: calc(var(--app-height) - 70px - 56px - var(--outgoingHeight));
          }
        }
      }

      .counsellor-layout {
        height: calc(var(--app-height) - 76px - 46px - var(--outgoingHeight)) !important;

        .history-list {
          .history-list--body {
            height: calc(100%);
          }
        }
      }

      .call-section {
        height: var(--app-height);
        position: relative;

        @media (max-width: $mediaWidthMD) {
          height: calc(var(--app-height) + 32px);
        }

        .video-call {

          .video-call-inside {

            .video-content {

              .call-chat {
                height: 100% !important;

                .call-chat-inside--body {
                  height: 100%;
                  flex: auto;

                  .chat-list {
                    max-height: calc(var(--app-height) - var(--overHeight) - 380px);

                    @media (max-width: $mediaWidthMD) {
                      max-height: calc(var(--app-height) - var(--overHeight) - 300px - 120px) !important;
                    }
                  }
                }
              }
            }
          }
        }
      }

      // TODO
      .chat-section {
        height: var(--app-height);
        position: relative;

        @media (max-width: $mediaWidthMD) {
          height: calc(var(--app-height) + 32px);
        }

        .video-call {

          .video-call-inside {

            .video-content {

              .call-chat {
                height: 100% !important;

                .call-chat-inside--body {
                  height: 100%;
                  flex: auto;

                  .chat-list {
                    max-height: calc(var(--app-height) - var(--overHeight) - 380px);

                    @media (max-width: $mediaWidthMD) {
                      max-height: calc(var(--app-height) - var(--overHeight) - 300px - 120px) !important;
                    }
                  }
                }
              }
            }
          }
        }
      }

      [class*="layout--desktop"] {

        [class*="-item--content"] {

          .content-container {
            .counsellor-layout {
              height: calc(var(--app-height) - var(--outgoingHeight)) !important;
            }
          }
        }
      }
    }
  }

  &--header.widget-header {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 24px;
    width: 100%;
    background-color: $widget-header-bg;
    transition: all 0.2s ease-out;
    height: 56px;

    .widget-header--title {
      @include font_T3_bold($white);
      flex: 1 0 auto;
    }

    .widget-header--actions {
      display: inline-flex;

      .actions-item {
        display: inline-flex;
        margin-right: 16px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        .react-custom-icon {
          color: $white !important;
        }
      }
    }
  }

  &--body.widget-body {
    flex: 1 0 auto;
    //height: calc(var(--app-height) - 56px - 32px);

    .main-layout {
      height: 100%;
    }

    .sidebar-widget {
      height: calc(100% - 17px);

      .expert-list--body {
        max-height: calc(var(--app-height) - 72px - 24px - 62px);
      }
    }

    [class*="layout--mobile"] {
      height: 100%;

      [class*="-item--content"] {
        height: 100%;

        .content-container {
          height: calc(100% - var(--outgoingHeight));

          .expert-list {
            margin-top: 0 !important;
          }

          .messages--body-empty {
            .empty-img {
              display: none;
            }
          }

          .business-information {
            height: calc(var(--app-height) - var(--outgoingHeight) - 45px) !important;
          }

          .terms-of-service, .privacy-policy, .notifications, .transactions:not(.transactions-counsellor) {
            height: calc(var(--app-height) - 30px - var(--outgoingHeight));

            @media (max-width: $mediaWidthMD) {
              height: calc(var(--app-height) - 30px - var(--outgoingHeight));
            }
          }

          .appointments {
            max-height: calc(var(--app-height) - 30px - var(--outgoingHeight));

            @media (max-width: $mediaWidthMD) {
              max-height: calc(var(--app-height) - 50px - var(--outgoingHeight));

              .appointments-mobile--list {
                max-height: calc(var(--app-height) - 50px - 76px - 80px);
              }
            }
          }

          .profile-settings {
            height: calc(var(--app-height) - 30px - var(--outgoingHeight));
            overflow: hidden;

            .profile-settings-inside {
              overflow-y: auto;
              height: calc(var(--app-height) - 30px - 55px - 80px - var(--outgoingHeight));

              .payments {
                overflow-x: hidden;
              }
            }
          }
        }
      }
    }

    [class*="layout--desktop"] {
      height: 100%;

      [class*="-item--content"] {
        height: 100%;

        .content-container {
          //height: calc(var(--app-height) - var(--outgoingHeight));
          height: 100%;

          .messages--body-empty {
            .empty-img {
              max-width: 200px;
            }
          }

          .business-information {
            height: calc(var(--app-height) - var(--outgoingHeight)) !important;
            overflow-y: auto;
          }

          .profile-settings {
            height: calc(var(--app-height) - var(--outgoingHeight)) !important;
            overflow: hidden;

            .profile-settings-content {
              height: 100%;
              box-sizing: border-box;
            }

            .profile-settings-inside {
              overflow-y: auto;
              height: calc(var(--app-height) - 88px - var(--outgoingHeight)) !important;

              .payments {
                overflow-x: hidden;
              }
            }
          }
        }
      }
    }

    .welcome {
      //height: var(--app-height);

      .expert-list--header {
        padding: 16px 24px 24px 24px;
      }

      .expert-list--body {
        max-height: calc(100% - 80px - 76px - var(--outgoingHeight));
      }
    }

    .counsellor-layout {
      height: calc(var(--app-height) - 56px - var(--outgoingHeight)) !important;

      .history-list {
        .history-list--body {
          height: calc(100% - 24px);
        }
      }
    }

    .auth {
      height: var(--app-height);
      min-height: unset;
      overflow: hidden;
      overflow-y: auto;

      .auth-inside {
        height: auto !important;
        padding-bottom: 0;
      }

      .footer {
        //position: relative;
        display: none !important;
      }
    }

    .call-section {
      height: calc(var(--app-height));

      .devices-settings {
        padding: 24px;
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;

        .inside-section {
          .title {
            margin-top: 0;
          }
        }
      }

      .payments-types {
        padding: 24px 24px 0;

        .title {
          display: flex;
          align-items: center;
          margin: 0 auto 24px;
          justify-content: center;
        }

        .inside-section {
          .payments-items {
            min-height: 300px !important;
          }
        }
      }

      .call-checkout {
        .title {
          margin: 0 auto 24px;
          padding-top: 0;
        }
      }

      .call-outgoing, .call-notification-page {
        height: 100%;
      }

      .call-finished, .stay-with-us-page {
        height: 100% !important;
        position: relative;
      }

      .video-call {
        height: 100%;

        .video-call-inside {
          height: 100% !important;
          display: flex;
          flex-direction: column;

          .video-header {
            .video-header-inside {
              padding: 16px 24px;
            }
          }

          .video-content {
            overflow-y: auto;
            flex: 1 0 auto;
            height: calc(var(--app-height) - 210px - 104px);

            .call-main {
              flex-direction: row;
              gap: 32px;

              .subscriber-video {
                .subscriber-section {
                  height: 100%;

                  .OT_subscriber {
                    height: 100%;
                    min-height: unset !important;
                    max-height: unset !important;
                  }
                }
              }

              @media (max-width: $mediaWidthMD) {
                .bad-connection-spinner {
                  height: 100% !important;

                  .spin-element {
                    height: 100% !important;
                  }
                }
              }
            }

            .call-chat {
              height: 100% !important;

              .call-chat-inside--body {
                height: 100%;
                flex: auto;

                .chat-list {
                  max-height: calc(var(--app-height) - var(--overHeight) - 360px);

                  @media (max-width: $mediaWidthMD) {
                    max-height: calc(var(--app-height) - var(--overHeight) - 300px - 104px) !important;
                  }
                }
              }
            }
          }
        }
      }

      .stay-with-us-page {
        padding: 24px;
        box-sizing: border-box;
      }
    }

    // TODO
    .chat-section {
      height: calc(var(--app-height));

      .chat-types {
        padding: 24px 24px 0;

        .title {
          display: flex;
          align-items: center;
          margin: 0 auto 24px;
          justify-content: center;
        }

        .inside-section {
          .chat-types-list .chat-types-items {
            min-height: 300px !important;
          }
        }
      }

      .chat-checkout {
        .title {
          margin: 0 auto 24px;
          padding-top: 0;
        }
      }

      .chat-outgoing, .conversation-notifications-page {
        height: 100%;
      }

      .chat-finished, .chat-stay-with-us-page {
        height: 100% !important;
        position: relative;
      }

      .chat-in-progress {
        height: 100%;

        .chat-in-progress-inside {
          height: 100% !important;
          display: flex;
          flex-direction: column;

          .chat-header {
            .chat-header-inside {
              padding: 16px 24px;
            }
          }

          .chat-content {
            overflow-y: auto;
            flex: 1 0 auto;
            height: calc(var(--app-height) - 210px);

            .chat-content--main {
              gap: 32px;
            }

            .chat-content--chat {
              height: 100% !important;

              .conversation-chat-inside--body {
                height: 100%;
                flex: auto;

                .chat-list {
                  max-height: calc(var(--app-height) - var(--overHeight) - 360px);

                  @media (max-width: $mediaWidthMD) {
                    max-height: calc(var(--app-height) - var(--overHeight) - 290px) !important;
                  }
                }
              }
            }
          }
        }
      }

      .chat-stay-with-us-page {
        padding: 24px;
        box-sizing: border-box;
      }
    }
  }


  &.widget-wrapper-embedded {

    .widget-footer {

      @media (min-width: $mediaWidthMD + 1) {
        display: none !important;
      }
    }

    .widget-wrapper--body.widget-body {

      .auth {
        @media (min-width: $mediaWidthMD + 1) {
          height: 100% !important;
        }
      }

      .sidebar-widget {
        .expert-list--body {

          @media (min-width: $mediaWidthMD + 1) {
            max-height: calc(var(--app-height) - 72px - 24px - 30px) !important;
          }
        }
      }

      .desktop-item--content {
        .counsellor-layout {

          @media (min-width: $mediaWidthMD + 1) {
            height: calc(var(--app-height) - var(--outgoingHeight)) !important;
          }
        }
      }

      .desktop-item--right-menu {
        .right-menu {

          @media (min-width: $mediaWidthMD + 1) {
            height: 100% !important;
          }
        }
      }
    }

    &.no-auth-user {
      .widget-wrapper--body.widget-body {
        .sidebar-widget {
          .expert-list--body {
            max-height: calc(var(--app-height) - 30px - 56px) !important;

            @media (max-width: $mediaWidthMD) {
              max-height: calc(var(--app-height) - 30px - 56px - var(--outgoingHeight)) !important;
            }
          }

          .sidebar-widget-footer {
            display: none;
          }
        }

        .mobile-item--content {
          .counsellor-layout {
            height: calc(var(--app-height) - 76px - 32px) !important;
          }
        }

        .welcome .expert-list--body {
          max-height: calc(var(--app-height) - 76px - 40px - var(--outgoingHeight)) !important;
        }
      }
    }

    &.auth-user {
      .widget-wrapper--body.widget-body {

        .sidebar-widget {
          .expert-list--body {

            @media (min-width: $mediaWidthMD + 1) {
              max-height: calc(var(--app-height) - 72px - 12px - 76px) !important;
            }
          }
        }

        .welcome, .history--mobile, .favorites {
          .expert-list--body {
            max-height: calc(var(--app-height) - 76px - 80px - 16px - var(--outgoingHeight));
          }
        }


        .main-layout--current-expert {
          .mobile-item--content {
            height: calc(100% - 76px - 80px);

            .counsellor-layout {
              height: calc(var(--app-height) - 76px - 80px - var(--outgoingHeight)) !important
            }
          }
        }

        .mobile-item--content {
          .notifications,
          .terms-of-service,
          .privacy-policy,
            //.business-information
          {
            height: calc(var(--app-height) - 76px - var(--outgoingHeight)) !important;
          }

          .transactions {
            height: 100% !important;
          }

          .profile-settings {
            height: calc(var(--app-height) - 76px - var(--outgoingHeight)) !important;

            .profile-settings-inside {
              height: calc(var(--app-height) - 30px - 76px - 80px - 16px - var(--outgoingHeight)) !important;
            }
          }
        }

        .desktop-item--content {
          .counsellor-layout {

            @media (min-width: $mediaWidthMD + 1) {
              height: 100% !important;
            }
          }

          .business-information {
            height: calc(var(--app-height) - 76px - 56px - 24px) !important;

            @media (min-width: $mediaWidthMD + 1) {
              height: calc(var(--app-height)) !important;
            }
          }

          .transactions:not(.transactions-counsellor) {
            height: calc(100% - var(--outgoingHeight)) !important;
          }

          .terms-of-service, .privacy-policy, .notifications {
            height: calc(var(--app-height) - var(--outgoingHeight)) !important;
          }

          .profile-settings {
            height: calc(var(--app-height) - var(--outgoingHeight)) !important;
            overflow: hidden;

            .profile-settings-inside {
              overflow-y: auto;
              height: calc(var(--app-height) - 90px - var(--outgoingHeight)) !important;

              .payments {
                overflow-x: hidden;
              }
            }
          }
        }
      }
    }
  }

  &--footer.widget-footer {
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    img {
      width: 100%;
      max-width: 122px;
      cursor: pointer;
    }

    .react-custom-icon-big, .react-custom-icon-big div {
      display: inline-flex;
    }

    &.call-state, &.chat-state {
      background-color: $call-bg;

      .react-custom-icon-big {
        color: $white !important;
      }
    }

    img {
      z-index: 1;
    }
  }
}
