@import '../../../styles/variables';

.sign-up-form {
  .form-bottom.form-bottom-block {
    display: block;

    .actions {
      display: flex;
      justify-content: flex-end;
    }
  }

  .checkbox-item {
    margin-left: 12px;
    margin-bottom: 0;

    .ant-checkbox-disabled + span {
      color: initial;
    }

    .ant-checkbox-wrapper-disabled {
      cursor: auto;
    }

    .ant-checkbox {
      .ant-checkbox-inner {
        border-radius: 2px;
        border: 1px solid $primary-40;
      }
    }

    .ant-checkbox-wrapper-checked {
      .ant-checkbox-inner {
        background-color: $primary-40;
      }
    }

    .ant-checkbox-disabled {
      .ant-checkbox-inner {
        &:after {
          border-top: 0;
          top: 44%;
          border-bottom: 1px solid $white;
          border-right: 1px solid $white;
        }
      }
    }

    span.modal-action {
      margin-left: 4px;
      @include font_P2_reg($blue-100);

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .hidden-clickable-space {
      position: absolute;
      left: 0;
      top: 7px;
      width: 16px;
      height: 16px;
      background-color: transparent;
    }

    .check-box-text {
      @include font_F_Elements($primary-60);
    }
  }

  .form-bottom {
    margin-top: 20px;

    .info-text {
      margin-left: 16px;
    }
  }
}
