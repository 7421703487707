@import '../../styles/variables';

.stay-with-us-modal {
  .ant-modal {
    .ant-modal-header {
      padding-right: 24px;
      margin-bottom: 12px !important;
    }

    .ant-modal-close {
      position: absolute;
      top: 24px;
      inset-inline-end: 24px;
    }

    &-footer {
      margin-top: 24px;

      .ant-btn {
        margin-left: auto;
      }
    }
  }

  .modal-body {
    @include font_P2_reg($primary-90);
  }
}
