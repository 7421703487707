@import "src/styles/variables";

.expert-auth-widget-navigation {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  &--item {
    display: inline-flex;
    position: relative;
    cursor: pointer;

    &.item-menu {
      align-items: center;
      margin-left: 16px;

      &.open-menu {
        .react-custom-icon {
          transform: rotate(180deg);
        }
      }

      .react-custom-icon {
        cursor: pointer;
        transition: all 0.3s ease;
        color: $primary-100 !important;
      }
    }

    &.menu-list {
      position: fixed;
      overflow: hidden;
      z-index: 10;
      width: 100%;
      top: 136px;
      left: 0;
      height: var(--app-height);

      &[hidden] {
        padding: 0;
      }

      .right-menu {
        width: 100%;
        height: calc(var(--app-height) - 32px);
        max-width: unset;

        &-body {
          justify-content: flex-start;

          .menu-list {
            justify-content: flex-start;

            .top-section, .center-section {
              .ant-tooltip--wrapper {
                margin-bottom: 16px !important;
              }
            }

            .center-section {
              flex: 0 0 auto;
              padding-bottom: 0;

              .ant-tooltip--wrapper:last-child {
                margin-bottom: 0 !important;
              }
            }

            .bottom-section {
              flex: 1 0 auto;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
